import { useTranslation } from 'react-i18next';
import {
  httpApi,
  ApiError,
  useAppMutation,
  Toast,
  ListAccessessType,
  useAuthStore,
  User,
} from '@exertis/template';
import { UseMutationResult } from '@tanstack/react-query';
import { compile } from 'path-to-regexp';

/**
 * API to edit rows on list
 * @param id
 * @param data
 * @returns
 */
const editAccess = async (
  id: string,
  data: ListAccessessType
): Promise<ListAccessessType[]> => {
  return await httpApi.patch(
    compile('/accesses/:id', { encode: encodeURIComponent })({ id }),
    data
  );
};
/**
 * hook to call api edit user
 * @returns
 */
export default function useEditAccess(): UseMutationResult<
  any,
  ApiError,
  any,
  any
> {
  const { setMe } = useAuthStore();
  const { t } = useTranslation();
  return useAppMutation(
    ['accessLists', 'accessList'],
    ({ id, data }: { id: string; data: ListAccessessType }) =>
      editAccess(id, data),
    {
      onPostSuccess: async () => {
        const me = await httpApi.get<User>('/me');
        setMe(me);
        Toast({
          title: t('common:apiRepsonse.successEdit'),
          method: 'success',
        });
      },
      onPostError: (error: ApiError) => {
        if (error?.message) {
          Toast({
            title: error.message,
            method: 'error',
          });
        }
        if (error?.errors) {
          error?.errors.map((err) =>
            Toast({
              title: err.message,
              method: 'error',
            })
          );
        }
      },
    }
  );
}
