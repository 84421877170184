import React from 'react';
import { AppData, BlackTypography } from '@exertis/template';
import { useTranslation } from 'react-i18next';

import { Grid, Select, MenuItem, SelectChangeEvent } from '@mui/material';

type Props = {
  appList: AppData[] | undefined;
  selectedApp: string;
  setSelectedApp(selectedApp: string): void;
};

const RoleManagementFilter = (props: Props) => {
  /**
   * Translation
   */
  const { t } = useTranslation();

  /**
   * Init props
   */
  const { appList, selectedApp, setSelectedApp } = props;

  return (
    <React.Fragment>
      <Grid
        container
        sx={{ mb: { xs: 10, md: 10, lg: 10 } }}
        alignItems={'center'}
      >
        <Grid item xs={12} sm={2}>
          <BlackTypography>
            {t('roleManagement:roleManagementFilter.selectApp')}
          </BlackTypography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Select
            fullWidth
            value={selectedApp}
            onChange={(event: SelectChangeEvent) => {
              setSelectedApp(event.target.value);
            }}
          >
            {appList?.map((item: AppData) => (
              <MenuItem key={item.id} value={item.id}>
                {item.applicationName}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default RoleManagementFilter;
