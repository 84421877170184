import { BlackTypography } from '@exertis/template';
import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const DeniedAccessAppWarning = () => {
  const { t } = useTranslation();
  return (
    <>
      <Grid container xs={12} justifyContent={'center'} alignItems={'center'}>
        <Grid
          container
          xs={12}
          mt={1}
          padding={5}
          style={{ backgroundColor: '#fff4e5' }}
          spacing={10}
          alignItems={'center'}
        >
          <Grid>
            <WarningAmberIcon style={{ color: '#f6b57c' }} />
          </Grid>
          <Grid ml={5}>
            <BlackTypography>{t('dashboard:header.warning')}</BlackTypography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DeniedAccessAppWarning;
