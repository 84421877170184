import { useQuery } from '@tanstack/react-query';
import { ApiError, Toast, httpApi } from '@exertis/template';
import { ApiFeatureResponse } from '../../types/FeatureTypes';

export function useGetFeatures(appId: string | null) {
  let url = `/features?limit=0&filter[app.id][eq]=${appId}`;
  return useQuery(
    ['features', appId],
    async (): Promise<ApiFeatureResponse> => await httpApi.get(url),
    {
      onSuccess() {},
      onError(error: ApiError) {
        if (error?.message) {
          Toast({
            title: error.message,
            method: 'error',
          });
        }
      },
    }
  );
}
