import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthStore, useMenuStore } from '@exertis/template';

export const useInitMenu = () => {
  const { t } = useTranslation();
  const { me } = useAuthStore();
  const { setItems } = useMenuStore();

  const dashboardSection = [
    {
      href: '/',
      title: t('layout:sideBar.dashboardList.accessPortal'),
    },
    {
      href: '/apps-by-role',
      title: t('layout:sideBar.dashboardList.listAppRole'),
    },
    {
      href: '/access',
      title: t('layout:sideBar.dashboardList.accessList'),
    },
    {
      href: '/role-management',
      title: t('layout:sideBar.dashboardList.roleManagement'),
    },
  ];

  const sidebarItems = [
    {
      title: t('layout:sideBar.title'),
      pages: dashboardSection,
    },
  ];

  useEffect(() => {
    const init = () => {
      if (
        me &&
        !(
          me?.role.roleName === 'it' &&
          me?.globalRole.globalRoleName === 'collaborateurs'
        )
      ) {
        const _sidebarItems = sidebarItems.map((sidebarItem) => {
          return {
            title: sidebarItem.title,
            pages: sidebarItem.pages.filter(
              (sidebarItemPage) => sidebarItemPage.href === '/'
            ),
          };
        });
        setItems(_sidebarItems);
      } else {
        setItems(sidebarItems);
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, localStorage.getItem('language')]);
};
