import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import {
  HeaderAppsType,
  useAuthStore,
  AppData,
  Toast,
  HeaderPricing,
} from '@exertis/template';

import ApplicationByRoleFilter from '../components/applicationByRole/ApplicationByRoleFilter';
import ApplicationByRoleList from '../components/applicationByRole/ApplicationByRoleList';
import { useGetListRole } from '../hooks/access/useGetRole';
import { useGetGlobalRoleList } from '../hooks/access/useGetGlobalRole';
import { useGetApplicationByRole } from '../hooks/application/useCrudApplicationByRole';
import { useGetApplications } from '../hooks/application/useCrudApplications';
import BackdropLoader from '../common/layouts/BackdropLoader';

type visibleApplications = {
  id: string;
  appId: string;
  applicationName: string;
  applicationUrl: string;
  status: string;
};

/**
 * Application role component
 *
 * @returns {JSX.Element}
 */
const AppsByRole = (): JSX.Element => {
  const { me } = useAuthStore();

  /**
   *   Translation
   */
  const { t } = useTranslation();

  /**
   * Global State
   */
  const [selectedGlobalRoleValue, setSelectedGlobalRoleValue] = useState({
    id: '',
    name: '',
  });
  const [selectedRoleValue, setSelectedRoleValue] = useState({
    id: '',
    name: '',
  });
  const [visibleApplications, setVisibleApplications] =
    useState<visibleApplications[]>();
  const [notVisibleApplications, setNotVisibleApplications] = useState<
    AppData[] | undefined
  >();
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isFilterAppliedFinished, setIsFilterAppliedFinished] = useState(false);
  const [isComboBoxSelected, setIsComboBoxSelected] = useState(false);

  /**
   * Get All Applications
   */
  const { data: allApplications, isInitialLoading: isAppsLoading } =
    useGetApplications();

  /**
   * Get All Application By Role
   */
  const { data: allAppByRole, isInitialLoading: isAppByRoleLoading } =
    useGetApplicationByRole(
      selectedGlobalRoleValue.id,
      selectedRoleValue.id,
      isFilterApplied
    );

  /**
   * Get globalRole list
   */
  const { data: globalRoleList } = useGetGlobalRoleList();

  /**
   * Get Role app list
   */
  const { data: appRoleList } = useGetListRole();

  /**
   * Get QueryClient from the context
   */
  const queryClient = useQueryClient();
  /**
   * Get language from local storage
   */
  const language = localStorage.getItem('language');

  /**
   * Handle language change
   */
  useEffect(() => {
    if (language) {
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: ['globalRoleList'],
        });
        queryClient.invalidateQueries({
          queryKey: ['role'],
        });
        queryClient.invalidateQueries({
          queryKey: ['accessLists'],
        });
      }, 1000);
    }
    // eslint-disable-next-line
  }, [language]);

  /**
   * Filter application visible and not visible after filter
   */
  useEffect(() => {
    if (
      selectedGlobalRoleValue.id &&
      selectedRoleValue.id &&
      allAppByRole &&
      allApplications &&
      isFilterApplied
    ) {
      let apps: any = [];
      allAppByRole?.data.filter((application: HeaderAppsType) => {
        apps.push({
          id: application.id,
          appId: application.appId,
          applicationName: application?.app?.applicationName,
          applicationUrl: application?.app?.applicationUrl,
          logoApplicationImg: application?.app?.logoApplicationImg,
          status: application?.app?.status,
          logoApplicationDisabledImg:
            application?.app?.logoApplicationDisabledImg,
        });
        return null;
      });
      //filter non visible applications
      let notVisible = allApplications.data?.filter(
        ({ id: id1 }) => !apps.some(({ appId: id2 }: any) => id2 === id1)
      );

      setVisibleApplications(apps);
      setNotVisibleApplications(notVisible);

      if (isFilterAppliedFinished) {
        setTimeout(() => {
          setIsFilterApplied(false);
        }, 2000);
      }
    }
  }, [
    allApplications,
    allAppByRole,
    selectedGlobalRoleValue.id,
    selectedRoleValue.id,
    isFilterApplied,
    isFilterAppliedFinished,
  ]);

  /**
   * Execute when user doesn't apply filter to apps
   */
  const handleFilter = () => {
    if (!selectedGlobalRoleValue.id && !selectedRoleValue.id) {
      Toast({
        title: t('appsByRole:filtrePage.warningRoleAndGlobalRole'),
        method: 'warning',
      });
    } else if (!selectedGlobalRoleValue.id) {
      Toast({
        title: t('appsByRole:filtrePage.warningGlobalRole'),
        method: 'warning',
      });
    } else if (!selectedRoleValue.id) {
      Toast({
        title: t('appsByRole:filtrePage.warningRole'),
        method: 'warning',
      });
    } else {
      setIsFilterApplied(true);
      setIsFilterAppliedFinished(true);
    }
  };

  /**
   * First render
   */
  useEffect(() => {
    if (!selectedGlobalRoleValue.id && !selectedRoleValue.id) {
      const applicationAfterSearch: AppData[] | undefined =
        allApplications?.data;
      setNotVisibleApplications(applicationAfterSearch);
      setIsFilterApplied(false);
    }
    if (selectedGlobalRoleValue.id && selectedRoleValue.id) {
      setIsComboBoxSelected(true);
    }
  }, [selectedGlobalRoleValue, selectedRoleValue, allApplications]);

  return (
    <>
      <HeaderPricing titlePage={t('appsByRole:header.title')} />
      {(isAppsLoading || isAppByRoleLoading || !me) && (
        <BackdropLoader open={true} />
      )}

      <ApplicationByRoleFilter
        globalRoleList={globalRoleList?.data.sort((a, b): any => {
          let textA = a.globalRoleName;
          let textB = b.globalRoleName;
          if (textA && textB) {
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          } else return undefined;
        })}
        appRoleList={appRoleList?.data.sort((a, b): any => {
          let textA = a.roleName;
          let textB = b.roleName;
          if (textA && textB) {
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          } else return undefined;
        })}
        selectedGlobalRoleValue={selectedGlobalRoleValue}
        selectedRoleValue={selectedRoleValue}
        setIsFilterAppliedFinished={setIsFilterAppliedFinished}
        setSelectedGlobalRoleValue={setSelectedGlobalRoleValue}
        setSelectedRoleValue={setSelectedRoleValue}
        handleFilter={handleFilter}
      />
      <ApplicationByRoleList
        visibleApplications={visibleApplications}
        notVisibleApplications={notVisibleApplications}
        isComboBoxSelected={isComboBoxSelected}
        selectedGlobalRoleValue={selectedGlobalRoleValue}
        selectedRoleValue={selectedRoleValue}
        allApps={allApplications?.data}
        setVisibleApplications={setVisibleApplications}
        setNotVisibleApplications={setNotVisibleApplications}
        setIsFilterApplied={setIsFilterApplied}
        setIsFilterAppliedFinished={setIsFilterAppliedFinished}
      />
    </>
  );
};

export default AppsByRole;
