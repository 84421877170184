import { useTranslation } from 'react-i18next';
import { useQuery, UseMutationResult } from '@tanstack/react-query';
import { ApiError, Toast, httpApi, useAppMutation } from '@exertis/template';

import { ApiBusinessProfileResponse } from '../../types/BusinessProfileTypes';

export function useGetBusinessProfiles() {
  return useQuery(
    ['businessProfile'],
    async (): Promise<ApiBusinessProfileResponse> =>
      await httpApi.get('/business-profile-params'),
    {
      onSuccess() {},
      onError(error: ApiError) {
        if (error?.message) {
          Toast({
            title: error.message,
            method: 'error',
          });
        }
      },
    }
  );
}

export function AssociateBusinessProfileFeatures(): UseMutationResult<
  any,
  ApiError
> {
  const { t } = useTranslation();
  return useAppMutation(
    ['businessProfile'],
    async (data: any) =>
      await httpApi.post('/business-profile-params/features', data),
    {
      onPostSuccess: () => {
        Toast({
          title: t('roleManagement:apiResponse.associationSuccess'),
          method: 'success',
        });
      },
      onPostError: (error: ApiError) => {
        if (error?.message) {
          Toast({
            title: error.message,
            method: 'error',
          });
        }
        if (error?.errors) {
          error?.errors.map((err) =>
            Toast({
              title: err.message,
              method: 'error',
            })
          );
        }
      },
    }
  );
}
