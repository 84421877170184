import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useModalStore,
  useAppForm,
  FormControlInput,
  Toast,
  formModalPicture,
  dragAndDropPicture,
  AppData,
  PrimaryButton,
} from '@exertis/template';
import { object, string } from 'yup';
import { FormProvider } from 'react-hook-form';
import {
  Box,
  CardContent,
  Dialog,
  Grid,
  IconButton,
  Typography as MuiTypography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';

import FormControlInputFile from '../../common/layouts/FormControlInputFile';
import FormControlRadioButton from '../../common/layouts/FormControlRadioButton';
import {
  useAddApplication,
  useEditApplication,
} from '../../hooks/application/useCrudApplications';
import BackdropLoader from '../../common/layouts/BackdropLoader';

//Styled component
const StyledDialog = styled(Dialog)({
  '.MuiDialog-paper': {
    background: '#FAFAFA',
    borderRadius: '4px',
    color: '#fffff',
  },
});
const StyledIconButton = styled(IconButton)((props: any) => ({
  position: 'absolute',
  right: 8,
  top: 8,
  zIndex: 1,
  color: `${props.theme.palette.grey[500]}`,
}));

const StyledBox = styled(Box)({
  height: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${formModalPicture()})`,
  position: 'absolute',
  right: 0,
  top: 0,
  bottom: 0,
  left: '57%',
});

const TitleTypography = styled(MuiTypography)((props) => ({
  position: 'absolute',
  width: '100%',
  marginLeft: 'auto',
  marginRight: '15px',
  left: 0,
  right: 0,
  textAlign: 'center',
  color: `${props.theme.palette.primary.main}`,
  fontSize: 48,
}));

/**
 * Props type
 */
type Props = {
  tag: string;
  applicationToEdit: AppData | null;
  setApplicationToEdit(applicationToEdit: AppData | null): void;
  setIsFilterApplied(isFilterApplied: boolean): void;
  setIsFilterAppliedFinished(isFilterAppliedFinished: boolean): void;
  setTag(tag: string): void;
};

const ApplicationByRoleFormMdoal = (props: Props) => {
  /**
   * Translation
   */
  const { t } = useTranslation();

  /**
   * Init props
   */
  const {
    tag,
    applicationToEdit,
    setApplicationToEdit,
    setIsFilterApplied,
    setIsFilterAppliedFinished,
    setTag,
  } = props;

  /**
   * States from store
   */
  const { open, hideModal } = useModalStore();

  /**
   * Init states
   */
  const [pictureToShow, setPictureToShow] = useState<any>();
  const [picture, setPicture] = useState<any>(null);
  const [sizePictureError, setSizePictureError] = useState<string | null>(null);

  const [pictureToShowDisabled, setPictureToShowDisabled] = useState<any>();
  const [pictureDisabled, setPictureDisabled] = useState<any>(null);
  const [sizePictureErrorDisabled, setSizePictureErrorDisabled] = useState<
    string | null
  >(null);

  /**
   * Hook to edit application
   */
  const mutateEditApps = useEditApplication(
    setPictureToShow,
    setPictureToShowDisabled,
    setApplicationToEdit
  );

  /**
   * Hook to add new application
   */
  const mutateAdd = useAddApplication(
    setPictureToShow,
    setPictureToShowDisabled
  );

  /**
   * Schema validation form
   */
  const schema = object().shape({
    applicationName: string().required(
      t('appsByRole:listingPage.formModal.validation.required')
    ),
    applicationUrl: string()
      .matches(
        /((^https:\/\/))(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!-]))?/gm,
        t('appsByRole:listingPage.formModal.InputUrlError')
      )
      .required(t('appsByRole:listingPage.formModal.validation.required')),
    wikiLink: string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .matches(
        /((^https:\/\/))(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!-]))?/gm,
        t('appsByRole:listingPage.formModal.InputUrlError')
      ),
    status: string().required(
      t('appsByRole:listingPage.formModal.validation.required')
    ),
  });

  /**
   *
   * @param event Handle change picture
   */
  const onPictureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setPictureToShow(URL.createObjectURL(event.target.files[0]));
      const sizeOnMb = event.target.files[0].size / 1024 / 1024;

      if (sizeOnMb > 2) {
        setSizePictureError(
          t('appsByRole:listingPage.formModal.validation.pictureSize')
        );
      }
      const formData = new FormData();
      formData.append('logoApplicationImg', event.target.files[0]);
      setPicture(formData);
    }
  };

  const onPictureChangeDisbaled = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      setPictureToShowDisabled(URL.createObjectURL(event.target.files[0]));
      const sizeOnMb = event.target.files[0].size / 1024 / 1024;

      if (sizeOnMb > 2) {
        setSizePictureErrorDisabled(
          t('appsByRole:listingPage.formModal.validation.pictureSizeDisabled')
        );
      }
      const formData = new FormData();
      formData.append('logoApplicationDisabledImg', event.target.files[0]);
      setPictureDisabled(formData);
    }
  };

  /**
   * App form of react hook form
   */
  const appForm = useAppForm(schema);
  const { handleSubmit, control, reset } = appForm;

  /**
   *
   * @param data Handle submit form
   */
  const onSubmit = (data: any) => {
    if (applicationToEdit) {
      const dataToEdit: AppData = {
        id: applicationToEdit.appId
          ? applicationToEdit.appId
          : applicationToEdit.id,
        ...data,
        logoApplicationImg: picture ? picture.get('logoApplicationImg') : null,
        logoApplicationDisabledImg: pictureDisabled
          ? pictureDisabled.get('logoApplicationDisabledImg')
          : null,
      };
      mutateEditApps
        .mutateAsync({ id: dataToEdit.id, data: dataToEdit })
        .then(() => reset());
    } else {
      const dataToSave: AppData = {
        ...data,
        logoApplicationImg: picture ? picture.get('logoApplicationImg') : null,
        logoApplicationDisabledImg: pictureDisabled
          ? pictureDisabled.get('logoApplicationDisabledImg')
          : null,
      };

      if (sizePictureError) {
        Toast({ title: sizePictureError, method: 'error' });
      } else if (sizePictureErrorDisabled) {
        Toast({ title: sizePictureErrorDisabled, method: 'error' });
      } else {
        mutateAdd.mutateAsync(dataToSave).then(() => {
          setIsFilterApplied(true);
          reset();
        });
      }
    }
  };

  /**
   * Close modal
   */
  const handleCloseModal = () => {
    setApplicationToEdit(null);
    setTag('');
    hideModal();
    setPicture(null);
    setPictureDisabled(null);
    setPictureToShow('');
    setPictureToShowDisabled('');
    setIsFilterAppliedFinished(true);
  };

  /**
   * Reset form when add apps
   */
  useEffect(() => {
    if (tag === 'createApps') {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag]);

  useEffect(() => {
    reset();
  }, [applicationToEdit, reset]);

  return (
    <>
      <BackdropLoader open={mutateEditApps.isLoading || mutateAdd.isLoading} />
      <StyledDialog
        open={open}
        PaperProps={{
          sx: {
            width: '812px',
            height: '820px',
            borderRadius: '4px',
          },
        }}
      >
        <Grid container item direction="row-reverse">
          <StyledIconButton onClick={handleCloseModal}>
            <CloseIcon />
          </StyledIconButton>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Box
            sx={{
              p: 0,
              height: { xs: '199px', md: '199px', lg: '199px' },
              mt: 0,
            }}
            display="block"
          >
            <Grid container>
              <Grid item xs={12} md={12} lg={12}>
                <StyledBox
                  component="div"
                  display="block"
                  sx={{
                    height: { xs: '199px', md: '199px', lg: '199px' },
                  }}
                ></StyledBox>
                <TitleTypography
                  variant="h4"
                  sx={{
                    mt: { xs: 20, md: 20, lg: 20 },
                  }}
                >
                  {applicationToEdit
                    ? t('appsByRole:listingPage.formModal.titleEdit')
                    : t('appsByRole:listingPage.formModal.title')}
                </TitleTypography>
              </Grid>
            </Grid>
          </Box>
          <CardContent sx={{ px: { xs: 1, md: 5, lg: 15 } }}>
            <FormProvider {...appForm}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid rowSpacing={{ sm: 5, lg: 9 }} container>
                  <Grid item xs={12} sm={10} lg={8} sx={{ pt: 0 }}>
                    <FormControlInput
                      name="applicationName"
                      control={control}
                      label={t(
                        'appsByRole:listingPage.formModal.labelInputTitle'
                      )}
                      defaultValue={
                        applicationToEdit
                          ? applicationToEdit.applicationName
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <FormControlInputFile
                      name="logoApplicationImg"
                      label={t('appsByRole:listingPage.formModal.labelPicture')}
                      pictureToShow={
                        pictureToShow
                          ? pictureToShow
                          : applicationToEdit &&
                            applicationToEdit?.logoApplicationImg &&
                            tag === 'editVisibleApps'
                          ? `${applicationToEdit.logoApplicationImg}`
                          : applicationToEdit &&
                            applicationToEdit?.logoApplicationImg &&
                            tag === 'editApps'
                          ? `${applicationToEdit.logoApplicationImg}`
                          : dragAndDropPicture()
                      }
                      onChangeEvent={onPictureChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <FormControlInputFile
                      name="logoApplicationDisabledImg"
                      label={t(
                        'appsByRole:listingPage.formModal.labelPictureDisabled'
                      )}
                      pictureToShow={
                        pictureToShowDisabled
                          ? pictureToShowDisabled
                          : applicationToEdit &&
                            applicationToEdit?.logoApplicationDisabledImg &&
                            tag === 'editVisibleApps'
                          ? `${applicationToEdit.logoApplicationDisabledImg}`
                          : applicationToEdit &&
                            applicationToEdit?.logoApplicationDisabledImg &&
                            tag === 'editApps'
                          ? `${applicationToEdit.logoApplicationDisabledImg}`
                          : dragAndDropPicture()
                      }
                      onChangeEvent={onPictureChangeDisbaled}
                      idPicker="filePickerDisabled"
                    />
                  </Grid>
                  <Grid item xs={12} sm={11.5} lg={11.5}>
                    <FormControlInput
                      name="applicationUrl"
                      control={control}
                      label={t(
                        'appsByRole:listingPage.formModal.labelInputUrl'
                      )}
                      defaultValue={
                        applicationToEdit
                          ? applicationToEdit?.applicationUrl
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={11.5} lg={11.5}>
                    <FormControlInput
                      name="wikiLink"
                      control={control}
                      label={t('appsByRole:listingPage.formModal.wikiLinkUrl')}
                      inputRequired={false}
                      defaultValue={
                        applicationToEdit ? applicationToEdit?.wikiLink : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <FormControlRadioButton
                      name="status"
                      control={control}
                      label={t('appsByRole:listingPage.formModal.labelStatus')}
                      defaultValue={
                        applicationToEdit ? applicationToEdit?.status : ''
                      }
                    />
                  </Grid>
                </Grid>
                <Box textAlign="center">
                  <PrimaryButton
                    type="submit"
                    typography={
                      applicationToEdit
                        ? t('appsByRole:listingPage.formModal.editButton')
                        : t('appsByRole:listingPage.formModal.submitButton')
                    }
                    fontWeight={'600'}
                    fontSize={14}
                    style={{
                      width: 250,
                      height: 36,
                      marginTop: 60,
                      letterSpacing: 1.25,
                    }}
                  />
                </Box>
              </form>
            </FormProvider>
          </CardContent>
        </Grid>
      </StyledDialog>
    </>
  );
};

export default ApplicationByRoleFormMdoal;
