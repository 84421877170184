const config = {
  apiBaseUrl: import.meta.env.VITE_API_BASE_URL,
  pubSitesApiBaseUrl: import.meta.env.VITE_PUB_API_BASE_URL,
  statisticApiBaseUrl: import.meta.env.VITE_STAT_API_BASE_URL,
  newRelicLoggingEnabled: import.meta.env.VITE_NEWRELIC_LOGGING_ENABLED,
  newRelicApiKey: import.meta.env.VITE_NEWRELIC_API_KEY,
  accessPortalFrontUrl: import.meta.env.VITE_ACCESS_FRONT_BASE_URL,
};

export default config;
