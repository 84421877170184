import { useQuery } from '@tanstack/react-query';
import {
  httpApi,
  ApiGlobalRoleResponse,
  GlobalRoleType,
} from '@exertis/template';
import { compile } from 'path-to-regexp';

//Api to get list of globale role
export const getGlobalRole = async (): Promise<ApiGlobalRoleResponse> => {
  return await httpApi.get('/globalroleparams');
};

//hook to call api to get global role
export function useGetGlobalRoleList() {
  return useQuery(['globalRoleList'], () => getGlobalRole());
}

/**
 * api to get global list role by id information
 * @param id
 * @returns
 */
export const getGlobalRoleById = async (
  id: string
): Promise<GlobalRoleType> => {
  return await httpApi.get(
    compile('/globalroleparams/:id', { encode: encodeURIComponent })({ id })
  );
};

/**
 * hook to call api to get global role by id information
 * @param id
 * @returns
 */
export function UseGetGlobalRoleById(id: string) {
  return useQuery(['globalRole', id], () => getGlobalRoleById(id));
}
