import { httpApi } from '@exertis/template';
import { useQuery } from '@tanstack/react-query';

/**
 * Get random advertisement hook
 * @returns
 */
export function useGetRandomAdvertisement() {
  return useQuery(
    ['randomPub'],
    async (): Promise<any> =>
      await httpApi.get(`${import.meta.env.VITE_PUB_API_BASE_URL}/i/pubs`)
  );
}
