import { useTranslation } from 'react-i18next';

import { useController, UseControllerProps } from 'react-hook-form';
import {
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
} from '@mui/material';
import { FormHelperErrorText } from '@exertis/template';

interface Props extends UseControllerProps {
  label?: string;
  displayTextError?: boolean;
}

const FormControlRadioButton = ({
  displayTextError = true,
  ...props
}: Props) => {
  /**
   * Translation
   */
  const { t } = useTranslation();

  const { field, fieldState } = useController(props);

  return (
    <>
      {props.label && <FormLabel required>{props.label}</FormLabel>}
      <FormControl>
        <RadioGroup
          {...field}
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
        >
          <FormControlLabel
            control={<Radio value={'enabled'} />}
            label={t('appsByRole:listingPage.formModal.firstRadioInout')}
          />
          <FormControlLabel
            control={<Radio value={'disabled'} />}
            label={t('appsByRole:listingPage.formModal.secondRadioInput')}
          />
        </RadioGroup>
      </FormControl>
      {displayTextError && fieldState.error && (
        <FormHelperErrorText>{fieldState.error.message}</FormHelperErrorText>
      )}
    </>
  );
};
export default FormControlRadioButton;
