import React, { useEffect, useState } from 'react';
import {
  appThemeStyles,
  SearchComponent,
  TypographyPlaceholder,
  inputIcon,
  FilterTypography,
  useAuthStore,
} from '@exertis/template';
import { darken } from 'polished';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Chip as MuiChip,
  FormControl as MuiFormControl,
  Grid,
  MenuItem,
  Select,
} from '@mui/material';
import { styled } from '@mui/system';

// styled component
const FormControl = styled(MuiFormControl)({
  margin: '8px 8px 8px 0px',
});

const Chip = styled(MuiChip)({
  backgroundColor: '#f2f2f2',
  borderRadius: 30,
  height: 36,
  margin: 1,
  marginRight: 10,
  marginBottom: 10,
  '&:hover': {
    backgroundColor: `${darken(
      0.05,
      appThemeStyles.sidebar.header.background
    )}`,
  },
  '.MuiChip-root': {
    margin: 0,
  },
});

const IconSelect = styled('img')({
  marginRight: 10,
  width: 15,
  height: 15,
});

type ChipData = {
  key: number;
  label: string;
  value?: string;
  displayName?: string;
};

type Props = {
  globalRoleList: any;
  chipData: ChipData[] | null;
  setChipData(chipData: ChipData[]): void;
  setIsFilterApplied(isFilterApplied: boolean): void;
};
const AccessFiltre = (props: Props) => {
  //Translation
  const { t } = useTranslation();
  const { me } = useAuthStore();

  //Init props
  const { globalRoleList, chipData, setChipData, setIsFilterApplied } = props;

  //State of component filtre
  const [selectedRoleValue, setSelectedRoleValue] = useState({
    id: '',
    name: '',
    displayName: '',
  });
  const [searchByMail, setSearchByMail] = useState('');
  const [searchByCodeExertis, setSearchByCodeExertis] = useState('');
  const [selectedStatus, setSelectedStatus] = useState({
    value: '',
    label: '',
    displayName: '',
  });
  const [
    searchByEmailCorrespondentExertis,
    setSearchByEmailCorrespondentExertis,
  ] = useState('');
  const [language, setLanguage] = useState(localStorage.getItem('language'));

  // Handle change value of inputs
  const handleValueChangeMail = (event: any) => {
    setSearchByMail(event.target.value);
  };

  const handleValueChangeMailCorrespondentExertis = (event: any) => {
    setSearchByEmailCorrespondentExertis(event.target.value);
  };

  const handleValueChangeCodeExertis = (event: any) => {
    setSearchByCodeExertis(event.target.value);
  };

  /**
   * Access statues
   */
  const statusAccess = [
    { value: 'A', label: t('access:listPageTable.listStatus.pending') },
    { value: 'I', label: t('access:listPageTable.listStatus.invalidated') },
    { value: 'V', label: t('access:listPageTable.listStatus.validated') },
  ];

  /**
   * Reset filters
   */
  const handleResetSearchByMail = () => {
    setSearchByMail('');
  };

  const handleResetSearchByMailCorrospendentExertis = () => {
    setSearchByEmailCorrespondentExertis('');
  };

  const handleResetSearchByCodeExertis = () => {
    setSearchByCodeExertis('');
  };

  /**
   * Get language from local storage
   */
  // const language = localStorage.getItem('language');

  // Add chip
  const handleAddChipTags = () => {
    setIsFilterApplied(true);
    setChipData([
      {
        key: 1,
        label: selectedRoleValue.name,
        displayName: handleLabelTranslation(selectedRoleValue.name),
      },
      {
        key: 2,
        label: searchByMail,
      },
      {
        key: 3,
        label: searchByCodeExertis,
      },
      {
        key: 4,
        label: selectedStatus.label,
        value: selectedStatus.value,
        displayName: handleLabelTranslation(selectedStatus.value),
      },
      {
        key: 5,
        label: searchByEmailCorrespondentExertis,
      },
    ]);
  };

  /**
   * Handle status and global role label translations
   */
  const handleLabelTranslation = (label: string): string => {
    switch (label) {
      case 'A':
        return t('access:listPageTable.listStatus.pending');
      case 'I':
        return t('access:listPageTable.listStatus.invalidated');
      case 'V':
        return t('access:listPageTable.listStatus.validated');
      case 'clients':
        return t('common:globalRoles.clients');
      case 'collaborateurs':
        return t('common:globalRoles.collaborateurs');
      case 'fournisseurs':
        return t('common:globalRoles.fournisseurs');
      default:
        return '';
    }
  };

  //Delete chip and initiliase value of state in chip
  const handleDelete = (data: { key: number; label: string }) => () => {
    if (chipData)
      setChipData(chipData?.filter((item: any) => item.key !== data.key));

    if (data.key === 1)
      setSelectedRoleValue({
        id: '',
        name: '',
        displayName: '',
      });
    if (data.key === 2) setSearchByMail('');
    if (data.key === 3) setSearchByCodeExertis('');
    if (data.key === 4)
      setSelectedStatus({ label: '', value: '', displayName: '' });
    if (data.key === 5) setSearchByEmailCorrespondentExertis('');
  };

  useEffect(() => {
    setLanguage(localStorage.getItem('language'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem('language')]);

  /**
   * Handle change language
   */
  useEffect(() => {
    if (language) {
      setSelectedRoleValue({
        ...selectedRoleValue,
        displayName: handleLabelTranslation(selectedRoleValue.name),
      });
      setSelectedStatus({
        ...selectedStatus,
        displayName: handleLabelTranslation(selectedStatus.value),
      });

      handleAddChipTags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <React.Fragment>
      <Grid container sx={{ mb: { xs: 10, md: 10, lg: 10 } }}>
        <Grid item xs={10} md={12} lg={12}>
          <Box
            sx={{
              pb: 2,
            }}
            display="block"
          >
            <Grid item md={7} lg={8}>
              <Grid
                container
                alignItems="center"
                sx={{ mt: { xs: 5, md: 5, lg: 5 } }}
              >
                <FormControl
                  sx={{
                    width: {
                      xs: '100%',
                      sm: '100%',
                      md: '80%',
                      lg: '325px',
                    },
                  }}
                >
                  <Select
                    name="globalRole"
                    value={selectedRoleValue.id}
                    style={{ height: 36 }}
                    displayEmpty={true}
                    renderValue={(value) =>
                      value?.length ? (
                        globalRoleList?.find(
                          (element: any) => element.id === value
                        )?.displayName
                      ) : (
                        <TypographyPlaceholder>
                          {t('access:filtrePage.placeholderGlobalRole')}
                        </TypographyPlaceholder>
                      )
                    }
                    startAdornment={<IconSelect src={inputIcon()} />}
                  >
                    {globalRoleList?.map((role: any, key: number) => {
                      return (
                        <MenuItem
                          key={key}
                          value={role.id}
                          onClick={() => {
                            setSelectedRoleValue({
                              id: role.id,
                              name: role.globalRoleName,
                              displayName: role.globalRoleName,
                            });
                          }}
                        >
                          {role.displayName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl
                  sx={{
                    width: {
                      xs: '100%',
                      sm: '100%',
                      md: '80%',
                      lg: '325px',
                    },
                  }}
                >
                  <Select
                    name="status"
                    value={selectedStatus.value}
                    displayEmpty={true}
                    renderValue={(value) =>
                      value?.length ? (
                        statusAccess?.find((element) => element.value === value)
                          ?.label
                      ) : (
                        <TypographyPlaceholder>
                          {t('access:filtrePage.placeholderStatus')}
                        </TypographyPlaceholder>
                      )
                    }
                    style={{ height: 36 }}
                    startAdornment={<IconSelect src={inputIcon()} />}
                  >
                    {statusAccess?.map((status: any, key: number) => {
                      return (
                        <MenuItem
                          key={key}
                          value={status.value}
                          onClick={() =>
                            setSelectedStatus({
                              value: status.value,
                              label: status.label,
                              displayName: status.value,
                            })
                          }
                        >
                          {status.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={10} md={12} lg={12}>
          <Box>
            <Grid container>
              <SearchComponent
                searchValue={searchByEmailCorrespondentExertis}
                placeholder={t(
                  'access:filtrePage.placeholderSearchContirubteExertisMail'
                )}
                handleChangeSearch={handleValueChangeMailCorrespondentExertis}
                handleResetSearch={handleResetSearchByMailCorrospendentExertis}
                widthInput={'95%'}
              />
              <SearchComponent
                searchValue={searchByMail}
                placeholder={t('access:filtrePage.placeholderSearchEmail')}
                handleChangeSearch={handleValueChangeMail}
                handleResetSearch={handleResetSearchByMail}
                widthInput={'95%'}
              />
              <SearchComponent
                searchValue={searchByCodeExertis}
                placeholder={t('access:filtrePage.placeholderSearchByCode')}
                handleChangeSearch={handleValueChangeCodeExertis}
                handleResetSearch={handleResetSearchByCodeExertis}
                widthInput={'95%'}
              />
              {chipData?.map(
                (data: {
                  key: number;
                  label: string;
                  displayName?: string;
                }) => {
                  return (
                    data.label.length > 0 && (
                      <Chip
                        key={data.key}
                        label={
                          data.key === 1 || data.key === 4
                            ? data?.displayName
                            : data.label
                        }
                        onDelete={handleDelete(data)}
                      />
                    )
                  );
                }
              )}
            </Grid>
          </Box>
          <Grid container justifyContent={'flex-start'}>
            <FilterTypography
              onClick={handleAddChipTags}
              sx={{
                mt: { xs: 2, md: 0, lg: 3 },
                mb: { xs: 2, md: 0, lg: 0 },
                ml: { xs: 2, md: 2, lg: 2 },
              }}
            >
              {t('access:filtrePage.applyFilter')}
            </FilterTypography>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AccessFiltre;
