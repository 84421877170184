import { useQuery } from '@tanstack/react-query';
import { httpApi, ApiListAccessResponse } from '@exertis/template';

/**
 * api to get list of user access
 * @param paginationPage
 * @returns
 */
export const getListsAccess = async (
  paginationPage: number,
  globalRole: string,
  email: string,
  codeInterneExertis: string,
  status: string | undefined,
  emailCorrespondentExertis: string
): Promise<ApiListAccessResponse> => {
  let url = `/accesses?page=${paginationPage}&expand=globalRole,role,businessProfile`;

  if (globalRole) {
    url += `&filter[globalRole.globalRoleName][eq]=${globalRole}`;
  }

  if (email) {
    url += `&filter[email][like]=${email.trim()}`;
  }

  if (codeInterneExertis) {
    url += `&filter[companyExertisCode][eq]=${codeInterneExertis}`;
  }

  if (status) {
    url += `&filter[status][eq]=${status}`;
  }

  if (emailCorrespondentExertis) {
    url += `&filter[contributorExertisEmail][like]=${emailCorrespondentExertis}`;
  }

  return await httpApi.get(url);
};

/**
 * Hook to call api to get list of user
 * @param paginationPage
 * @returns
 */
export default function useGetLists(
  paginationPage: number,
  globalRole: string,
  email: string,
  codeInterneExertis: string,
  status: string | undefined,
  emailCorrespondentExertis: string
) {
  return useQuery(
    [
      'accessLists',
      paginationPage,
      globalRole,
      email,
      codeInterneExertis,
      status,
      emailCorrespondentExertis,
    ],
    () =>
      getListsAccess(
        paginationPage,
        globalRole,
        email,
        codeInterneExertis,
        status,
        emailCorrespondentExertis
      )
  );
}
