import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAuthStore,
  useModalStore,
  CardComponent,
  HeaderAppsType,
  PrimaryButton,
} from '@exertis/template';

import { Grid, Typography as MuiTypography } from '@mui/material';

import { styled, spacing } from '@mui/system';

import DashboardAddAppsModal from './DashboardAddAppsModal';
import exertisLogoGrey from '../../assets/exertis-logo-grey.svg';
import exertisLogoRed from '../../assets/exertis-logo-red.svg';

//Styled component
const GridContainer = styled(Grid)({
  background: '#f9f9f9',
  padding: 8,
  width: '100%',
  display: 'flex',
  marginBottom: 50,
});

const GridContent = styled(Grid)({
  userSelect: 'none',
  padding: 16,
  margin: `0 40px 15px 40px`,
  width: 218,
  height: 269,
  borderRadius: 4,
  background: '#FFFFFF',
});

const Typography = styled(MuiTypography)(spacing);

const DefaultLogo = styled('img')({
  width: 132,
  height: 30,
  filter: 'gray',
});
const Logo = styled('img')({
  width: 132,
  height: 132,
});

//Type props
type Props = {
  appsDisabled: HeaderAppsType[] | undefined;
  appsEnabled: HeaderAppsType[] | undefined;
};

const DashboardListing = (props: Props) => {
  const { t } = useTranslation();

  const { me } = useAuthStore();
  const { showModal } = useModalStore();

  /**
   * Props
   */
  const { appsEnabled, appsDisabled } = props;

  //Open link of app in new window
  const handleOpenLink = (url: string | undefined) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const addApplication = me?.globalRole.globalRoleName === 'collaborateurs' &&
    me?.role.roleName === 'it' && (
      <PrimaryButton
        type="submit"
        onClick={() => showModal()}
        typography={t('dashboard:listingPage.headerButton')}
        style={{ height: 35, marginTop: 5 }}
      />
    );
  return (
    <React.Fragment>
      <DashboardAddAppsModal />
      <CardComponent
        title={t('dashboard:listingPage.title')}
        actionButton={addApplication}
      >
        {appsEnabled?.length === 0 && appsDisabled?.length === 0 ? (
          <Typography variant="h6" ml={13} mb={7} textAlign="center">
            {t('dashboard:listingPage.emptyApps')}
          </Typography>
        ) : (
          <>
            <Typography variant="h6" ml={13} mb={7}>
              {t('dashboard:listingPage.appsEnabled')}
            </Typography>
            {appsEnabled?.length === 0 && (
              <Typography variant="h6" ml={13} mb={7} textAlign="center">
                {t('dashboard:listingPage.appsEnabledEmpty')}
              </Typography>
            )}
            <GridContainer
              justifyContent="flex-start"
              flexWrap={'wrap'}
              style={{ cursor: 'pointer' }}
            >
              {appsEnabled &&
                appsEnabled.map((list: HeaderAppsType, key: number) => {
                  return (
                    <GridContent
                      item
                      xs={12}
                      md={3}
                      lg={2}
                      key={key}
                      onClick={() => handleOpenLink(list.app?.applicationUrl)}
                    >
                      <Grid
                        container
                        justifyContent={'center'}
                        alignItems="center"
                        direction={'column'}
                        sx={{
                          mt: list.app?.logoApplicationImg ? 3 : 15,
                        }}
                      >
                        {list.app?.logoApplicationImg ? (
                          <Logo src={list.app?.logoApplicationImg} alt="" />
                        ) : (
                          <DefaultLogo src={exertisLogoRed} alt="" />
                        )}
                        <Typography
                          variant="h6"
                          mt={list.app?.logoApplicationImg ? 3 : 16.5}
                          style={{ color: '#9E9E9E', fontSize: 14 }}
                        >
                          {list.app?.applicationName}
                        </Typography>
                      </Grid>
                    </GridContent>
                  );
                })}
            </GridContainer>
            <Typography variant="h6" ml={13} mb={7}>
              {t('dashboard:listingPage.appsDisabled')}
            </Typography>
            {appsDisabled?.length === 0 && (
              <Typography variant="h6" ml={13} mb={7} textAlign="center">
                {t('dashboard:listingPage.appsDisabledEmpty')}
              </Typography>
            )}
            <GridContainer justifyContent="flex-start" flexWrap={'wrap'}>
              {appsDisabled &&
                appsDisabled.map((list: HeaderAppsType, key: number) => {
                  return (
                    <GridContent item xs={12} md={3} lg={2} key={key}>
                      <Grid
                        container
                        justifyContent={'center'}
                        alignItems="center"
                        direction={'column'}
                        sx={{
                          mt: list.app?.logoApplicationImg ? 3 : 15,
                        }}
                      >
                        {list.app?.logoApplicationImg ? (
                          <Logo src={list.app?.logoApplicationImg} alt="" />
                        ) : (
                          <DefaultLogo src={exertisLogoGrey} alt="" />
                        )}
                        <Typography
                          variant="h6"
                          mt={list.app?.logoApplicationImg ? 3 : 16.5}
                          style={{ color: '#9E9E9E', fontSize: 14 }}
                        >
                          {list.app?.applicationName}
                        </Typography>
                      </Grid>
                    </GridContent>
                  );
                })}
            </GridContainer>
          </>
        )}
      </CardComponent>
    </React.Fragment>
  );
};

export default DashboardListing;
