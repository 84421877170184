import { ThemeProvider } from '@emotion/react';
import { ErrorBoundary } from 'react-error-boundary';
import { useRoutes, useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  Authorization,
  ErrorFallback,
  queryClient,
  theme,
  setBaseUrl,
  useAuthStore,
} from '@exertis/template';
import './i18n';
import routes from './routes';
import config from './config';
import { useInitMenu } from './hooks/app';
import { useInitConfig } from './hooks/config';
import { useEffect } from 'react';

export default function App() {
  const { me, tokenInfo } = useAuthStore();

  let navigate = useNavigate();
  const location = useLocation();

  useInitMenu();
  useInitConfig();

  const content = useRoutes(routes);
  setBaseUrl(config.apiBaseUrl);

  useEffect(() => {
    if (me) {
      if (
        tokenInfo &&
        location.pathname === '/apps-by-role' &&
        !(
          me?.role.roleName === 'it' &&
          me?.globalRole.globalRoleName === 'collaborateurs'
        )
      ) {
        navigate('/AccessDenied');
      }

      if (
        tokenInfo &&
        location.pathname === '/access' &&
        !(
          me?.role.roleName === 'it' &&
          me?.globalRole.globalRoleName === 'collaborateurs'
        )
      ) {
        navigate('/AccessDenied');
      }

      if (
        tokenInfo &&
        location.pathname === '/role-management' &&
        !(
          me?.role.roleName === 'it' &&
          me?.globalRole.globalRoleName === 'collaborateurs'
        )
      ) {
        navigate('/AccessDenied');
      }
    }
  }, [tokenInfo, me, location.pathname, navigate]);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Authorization>{content}</Authorization>
        </ErrorBoundary>
        <ToastContainer />
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
}
