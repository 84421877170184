import React from 'react';
import {
  TypographyPlaceholder,
  inputIcon,
  FilterTypography,
} from '@exertis/template';
import { useTranslation } from 'react-i18next';

import {
  Box,
  FormControl as MuiFormControl,
  Grid,
  MenuItem,
  Select,
} from '@mui/material';
import { styled } from '@mui/system';

// styled component
const FormControl = styled(MuiFormControl)({
  margin: '8px 8px 8px 0px',
});

const IconSelect = styled('img')({
  marginRight: 10,
  width: 15,
  height: 15,
});

type RoleState = {
  id: string;
  name: string;
};

type Props = {
  globalRoleList: any;
  appRoleList: any;
  selectedGlobalRoleValue: RoleState;
  selectedRoleValue: RoleState;
  setSelectedGlobalRoleValue(selectedGlobalRoleValue: RoleState): void;
  setSelectedRoleValue(selectedRoleValue: RoleState): void;
  setIsFilterAppliedFinished(isFilterAppliedFinished: boolean): void;
  handleFilter(): void;
};
const ApplicationByRoleFilter = (props: Props) => {
  /**
   * Translation
   */
  const { t } = useTranslation();

  //Init props
  const {
    globalRoleList,
    appRoleList,
    selectedGlobalRoleValue,
    selectedRoleValue,
    setSelectedGlobalRoleValue,
    setSelectedRoleValue,
    setIsFilterAppliedFinished,
    handleFilter,
  } = props;

  return (
    <React.Fragment>
      <Grid container sx={{ mb: { xs: 10, md: 10, lg: 10 } }}>
        <Grid item xs={10} md={12} lg={12}>
          <Grid container alignItems="center">
            <FormControl
              sx={{
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '80%',
                  lg: '280px',
                },
                mr: { xs: 0, sm: 0, md: 10, lg: 10 },
              }}
            >
              <Select
                name="globalRole"
                placeholder="Role globale"
                value={selectedGlobalRoleValue.id}
                displayEmpty={true}
                renderValue={(value) =>
                  value?.length ? (
                    globalRoleList?.find((element: any) => element.id === value)
                      ?.displayName
                  ) : (
                    <TypographyPlaceholder>
                      {t('appsByRole:filtrePage.placeholderGlobalRole')}
                    </TypographyPlaceholder>
                  )
                }
                onFocus={() => setIsFilterAppliedFinished(true)}
                style={{ height: 36 }}
                startAdornment={<IconSelect src={inputIcon()} />}
              >
                {globalRoleList?.map((role: any, key: number) => {
                  return (
                    <MenuItem
                      key={key}
                      value={role.id}
                      onClick={() => {
                        setSelectedGlobalRoleValue({
                          id: role.id,
                          name: role.globalRoleName,
                        });
                      }}
                    >
                      {role.displayName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl
              sx={{
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '80%',
                  lg: '280px',
                },
                mr: { xs: 0, sm: 0, md: 10, lg: 10 },
              }}
            >
              <Select
                name="role"
                value={selectedRoleValue.id}
                displayEmpty={true}
                renderValue={(value) =>
                  value?.length ? (
                    appRoleList?.find((element: any) => element.id === value)
                      ?.displayName
                  ) : (
                    <TypographyPlaceholder>
                      {t('appsByRole:filtrePage.placeholderRole')}
                    </TypographyPlaceholder>
                  )
                }
                onFocus={() => setIsFilterAppliedFinished(true)}
                style={{ height: 36 }}
                startAdornment={<IconSelect src={inputIcon()} />}
              >
                {appRoleList?.map((role: any, key: number) => {
                  return (
                    <MenuItem
                      key={key}
                      value={role.id}
                      onClick={() => {
                        setSelectedRoleValue({
                          id: role.id,
                          name: role.roleName,
                        });
                      }}
                    >
                      {role.displayName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid container justifyContent={'flex-start'}>
            <FilterTypography
              sx={{
                mt: { xs: 5, sm: 5, md: 2, lg: 2 },
              }}
              onClick={() => handleFilter()}
            >
              {t('appsByRole:filtrePage.applyFilter')}
            </FilterTypography>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ApplicationByRoleFilter;
