import { useConfig } from '@exertis/template';
import { useEffect } from 'react';

import config from '../config';

export const useInitConfig = () => {
  const { setNewRelicLoggingEnabled, setNewRelicApiKey } = useConfig();

  useEffect(() => {
    setNewRelicLoggingEnabled(config.newRelicLoggingEnabled === '1');
    setNewRelicApiKey(config.newRelicApiKey);
  }, []);
};
