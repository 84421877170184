import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAuthStore,
  ApiListAccessResponse,
  ListAccessessType,
  Loader,
  TypographyDataGridEmtyRows,
  moreTableIcon,
  verifyPhoneNumberHasCode,
  ConfirmationModal,
  SuccessModal,
  HeaderTableTypography,
  Toast,
} from '@exertis/template';

import {
  Grid,
  IconButton,
  Pagination as MuiPagination,
  Typography as MuiTypography,
  Autocomplete,
  TextField as MuiTextField,
  Chip,
  CircularProgress,
} from '@mui/material';

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowModel,
  GridColumnHeaderParams,
  GridRenderEditCellParams,
} from '@mui/x-data-grid';
import { styled, spacing } from '@mui/system';

import MenuItemsComponent from './MenuItemsComponent';
import { useGetListRole } from '../../hooks/access/useGetRole';
import { useGenerateApiKey } from '../../hooks/access/useGenerateApiKey';
import ListAppsModal from './ListAppsModal';
import { BusinessProfileType } from '../../types/BusinessProfileTypes';

// Styled component
const ContainerTable = styled('div')({
  width: '100%',
  height: 'auto',
});

const TextFieldSpacing = styled(MuiTextField)(spacing);
const TextFieldAutoComplete = styled(TextFieldSpacing)<{ m?: number }>({
  '& .MuiOutlinedInput-root': {
    input: {
      padding: '2.5px 4px 7.5px 6px !important',
    },
  },
});

const StyledDataGrid = styled(DataGrid)({
  '.MuiDataGrid-row:nth-of-type(odd)': {
    background: '#f7f7f7',
  },
  '.MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '.MuiDataGrid-columnHeader--moving': {
    background: 'white',
  },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    padding: 0,
    paddingLeft: 10,
    borderRight: '0.5px solid #E0E0E0',
  },
  '.MuiDataGrid-cell--textLeft': {
    borderRight: '0.5px solid #E0E0E0',
    borderBottom: 'none',
  },
  '.MuiDataGrid-columnHeaderTitle': {
    fontWeight: '550',
    color: '#9E9E9E',
    fontSize: 14,
  },
  '.MuiDataGrid-cell': {
    borderBottom: 'none',
    minHeight: '40px !important',
    maxHeight: 'auto',
  },
  '.MuiDataGrid-row': {
    minHeight: '40px !important',
    maxHeight: 'auto',
  },
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: 'none',
  },
  '&.MuiDataGrid-root': {
    border: 'none',
    '.MuiDataGrid-row--editing': {
      boxShadow:
        '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    },
  },
  '.MuiOutlinedInput-root, .MuiSelect-root': {
    height: '85%',
    bottom: 1,
  },
});

const Pagination = styled(MuiPagination)(spacing);

const IconMoreAction = styled('img')({
  width: 15,
  height: 15,
});

const Typography = styled(MuiTypography)(spacing);

//type props
type Props = {
  data: ApiListAccessResponse | undefined;
  paginationPage: number;
  isLoadingDeleteUser: boolean;
  deleteUser?: any;
  mutateEdit?: any;
  isLoading: boolean;
  businessProfileOptions: any;
  isBusinessProfileLoading: boolean;
  setSearchByBusinessProfileName(searchByBusinessProfileName: string): void;
  setBusinessProfilePage(page: any): void;
  setPaginationPage(page: number): void;
};

const AccessList = (props: Props) => {
  const { me } = useAuthStore();

  /**
   * Translation
   */
  const { t } = useTranslation();

  /**
   * Init props
   */
  const {
    data,
    paginationPage,
    mutateEdit,
    isLoadingDeleteUser,
    deleteUser,
    isLoading,
    businessProfileOptions,
    isBusinessProfileLoading,
    setSearchByBusinessProfileName,
    setBusinessProfilePage,
    setPaginationPage,
  } = props;

  /**
   * Api calls
   */
  const { data: appRoleList } = useGetListRole();
  // const { data: businessProfileList } = useGetBusinessProfileList();
  const mutateGenerateApiKey = useGenerateApiKey();

  /**
   * States of component
   */
  const [user, setUser] = useState<any>({
    status: '',
  });
  const [tags, setTags] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [isListAppsModalVisible, setListAppsModalVisible] = useState(false);
  const [roleOptions, setRoleOptions] = useState<any>([]);
  const [businessProfileEdited, setBusinessProfileEdited] = useState<any>([]);

  useEffect(() => {
    if (appRoleList) {
      appRoleList?.data.map((role: any) => {
        setRoleOptions((currentRole: any) => [
          ...currentRole,
          { label: role.roleName, value: role.id },
        ]);
        return null;
      });
    }
  }, [appRoleList]);

  /**
   * Declation column of table
   */
  const columns: GridColDef[] = [
    {
      field: 'Action',
      headerName: '',
      sortable: false,
      align: 'center',
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        return (
          <>
            <IconButton
              onClick={(e: any) => handleOpenMenu(e, row)}
              disabled={
                me?.globalRole.globalRoleName !== 'collaborateurs'
                  ? true
                  : false
              }
            >
              <IconMoreAction src={moreTableIcon()} />
            </IconButton>
            <MenuItemsComponent
              anchorEl={anchorEl}
              user={user}
              mutateGenerateApiKey={mutateGenerateApiKey}
              handleCloseMenu={handleCloseMenu}
              showDialog={showDialog}
              showAppsListModal={showAppsListModal}
              setTags={setTags}
            />
          </>
        );
      },
    },
    {
      field: 'status',
      headerName: t('access:listPageTable.status'),
      sortable: false,
      width: 80,
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        if (row.status === 'V') {
          return t('access:listPageTable.listStatus.validated');
        } else if (row.status === 'I') {
          return t('access:listPageTable.listStatus.invalidated');
        } else {
          return t('access:listPageTable.listStatus.pending');
        }
      },
    },
    {
      field: 'globalRole',
      headerName: t('access:listPageTable.globalRole'),
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        return row?.globalRole.displayName || row?.globalRole.globalRoleName;
      },
    },
    {
      field: 'companyName',
      headerName: t('access:listPageTable.companyName'),
      sortable: false,
      width: 300,
    },
    {
      field: 'companyExertisCode',
      headerName: t('access:listPageTable.companyExertisCode'),
      width: 170,
    },
    {
      field: 'email',
      headerName: t('access:listPageTable.email'),
      width: 200,
    },
    {
      field: 'contributorExertisEmail',
      sortable: false,
      headerName: t('access:listPageTable.contributorExertisEmail'),
      width: 240,
    },
    {
      field: 'roleId',
      headerName: t('access:listPageTable.role'),
      sortable: false,
      width: 150,
      valueOptions:
        roleOptions?.sort((a: any, b: any): any => {
          let textA = a.label;
          let textB = b.label;
          if (textA && textB) {
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          } else return undefined;
        }) || '',
      type: 'singleSelect',
      editable:
        me?.role.roleName === 'it' &&
        me?.globalRole.globalRoleName === 'collaborateurs'
          ? true
          : false,
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        return row?.role?.displayName || row?.role?.roleName;
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <HeaderTableTypography>
            {params.colDef.headerName}
          </HeaderTableTypography>
        );
      },
    },
    {
      field: 'businessProfile',
      headerName: t('access:listPageTable.businessProfile'),
      sortable: false,
      width: 200,
      editable:
        me?.role.roleName === 'it' &&
        me?.globalRole.globalRoleName === 'collaborateurs'
          ? true
          : false,
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        return (
          row?.businessProfiles?.map((item: any) => item?.name).join(', ') || ''
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        const { row } = params;
        return (
          <Autocomplete
            options={businessProfileOptions || []}
            multiple
            fullWidth
            getOptionLabel={(option: BusinessProfileType) => option.name || ''}
            defaultValue={businessProfileOptions.filter((item: any) =>
              row?.businessProfiles
                .map((business: any) => business.id)
                .includes(item.id)
            )}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option?.name || ''}
                </li>
              );
            }}
            onChange={(
              _: React.SyntheticEvent<Element, Event>,
              newValue: any
            ) => {
              setBusinessProfileEdited(newValue);
            }}
            onInputChange={(event, newInputValue, reason) => {
              if (reason === 'reset') {
                setSearchByBusinessProfileName('');
                return;
              }
            }}
            onBlur={() => setSearchByBusinessProfileName('')}
            renderInput={(params) => (
              <TextFieldAutoComplete
                {...params}
                placeholder={''}
                onChange={(event: any) => {
                  setSearchByBusinessProfileName(event.target.value);
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isBusinessProfileLoading ? (
                        <CircularProgress color="primary" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option?.name || ''}
                  size="small"
                  {...getTagProps({ index })}
                />
              ))
            }
            ListboxProps={{
              onScroll: (event) => {
                const listboxNode = event.currentTarget;
                if (
                  listboxNode.scrollTop + listboxNode.clientHeight ===
                  listboxNode.scrollHeight
                ) {
                  setBusinessProfilePage((prev: any) => prev + 1);
                }
              },
            }}
          />
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <HeaderTableTypography>
            {params.colDef.headerName}
          </HeaderTableTypography>
        );
      },
    },
    {
      field: 'lastName',
      headerName: t('access:listPageTable.lastName'),
      sortable: false,
      editable:
        me?.role.roleName === 'it' &&
        me?.globalRole.globalRoleName === 'collaborateurs'
          ? true
          : false,
      width: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <HeaderTableTypography>
            {params.colDef.headerName}
          </HeaderTableTypography>
        );
      },
    },
    {
      field: 'firstName',
      headerName: t('access:listPageTable.firstName'),
      sortable: false,
      editable:
        me?.role.roleName === 'it' &&
        me?.globalRole.globalRoleName === 'collaborateurs'
          ? true
          : false,
      width: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <HeaderTableTypography>
            {params.colDef.headerName}
          </HeaderTableTypography>
        );
      },
    },
    {
      field: 'addressLine',
      headerName: t('access:listPageTable.addressLine'),
      sortable: false,
      editable: false,
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        let chaine = '';
        if (row?.addressLine) {
          chaine += row?.addressLine + ' | ';
        }
        if (row?.postalCode) {
          chaine += row?.postalCode + ' | ';
        }
        if (row?.city) {
          chaine += row?.city + ' | ';
        }
        if (row?.country) {
          chaine += row?.country;
        }
        return chaine;
      },
    },
    {
      field: 'phoneNumber',
      headerName: t('access:listPageTable.phoneNumber'),
      sortable: false,
      width: 150,
      editable:
        me?.role.roleName === 'it' &&
        me?.globalRole.globalRoleName === 'collaborateurs'
          ? true
          : false,
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        const phoneNumber = row?.countryIndicator + ' ' + row.phoneNumber;

        return row.countryIndicator && row.phoneNumber ? phoneNumber : '';
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <HeaderTableTypography>
            {params.colDef.headerName}
          </HeaderTableTypography>
        );
      },
    },
    {
      field: 'validatorEmail',
      sortable: false,
      headerName: t('access:listPageTable.validatorEmail'),
      width: 200,
    },
  ];

  // Handle change page pagination
  const handleChangePage = (_: any, value: any) => {
    setPaginationPage(value);
  };

  // Open action menu for specific row
  const handleOpenMenu = (event: any, list: ListAccessessType) => {
    setAnchorEl(event?.currentTarget);
    setUser(list);
  };

  // Close action menu for specific row
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Show confirmation dialog
  const showDialog = () => {
    setIsModalVisible(true);
    setAnchorEl(null);
  };

  /**
   * Show apps list modal
   */
  const showAppsListModal = () => {
    setListAppsModalVisible(true);
    setAnchorEl(null);
  };

  // Hide confirmation dialog
  const hideDialog = () => {
    setTags('');
    setIsModalVisible(false);
  };

  // Hide success modal
  const hideSuccessModal = () => {
    setIsModalVisible(false);
    setIsSuccessModalVisible(false);
    setTags('');
  };

  // Hide apps list modal
  const hideAppsListModal = () => {
    setTags('');
    setListAppsModalVisible(false);
  };

  // Get from table row updated with new value
  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    const rowAfterChange = {
      ...newRow,
      globalRole: newRow?.globalRole?.id,
      role: newRow?.roleId,
      businessProfiles: businessProfileEdited.map((item: any) => item.id),
    };
    if (
      rowAfterChange.lastName !== oldRow.lastName ||
      rowAfterChange.firstName !== oldRow.firstName ||
      rowAfterChange.role !== oldRow.roleId ||
      rowAfterChange.addressLine !== oldRow.addressLine ||
      rowAfterChange.phoneNumber !== oldRow.phoneNumber ||
      rowAfterChange.businessProfiles !== oldRow.businessProfiles
    ) {
      if (
        isNaN(rowAfterChange?.phoneNumber) ||
        rowAfterChange?.phoneNumber?.length < 8
      ) {
        Toast({
          title: t('access:listPageTable.validation.phone'),
          method: 'error',
        });
        return;
      }

      if (rowAfterChange?.phoneNumber !== oldRow?.phoneNumber) {
        let result = verifyPhoneNumberHasCode(rowAfterChange.phoneNumber);

        if (result.codeCountry) {
          mutateEdit.mutateAsync({
            id: rowAfterChange?.id,
            data: {
              ...rowAfterChange,
              phoneNumber: result.phoneNumber,
              countryIndicator: result.codeCountry,
            },
          });
        } else {
          mutateEdit.mutateAsync({
            id: rowAfterChange?.id,
            data: { ...rowAfterChange, phoneNumber: result.phoneNumber },
          });
        }
      } else {
        mutateEdit.mutateAsync({
          id: rowAfterChange?.id,
          data: rowAfterChange,
        });
      }
    }

    return newRow;
  };

  /**
   * Action to do after user confirmation confirmation
   * @param confirmation
   */
  const handleModalConfirmation = async (confirmation: boolean) => {
    if (confirmation && tags === 'deleteAccount') {
      deleteUser(user?.id).then(() => {
        setIsSuccessModalVisible(true);
      });
    }
    if (confirmation && tags === 'activateAccount') {
      const dataToUpdate = {
        ...user,
        globalRole: user?.globalRole?.id,
        role: user?.roleId,
      };
      delete dataToUpdate?.globalRoleId;

      mutateEdit
        .mutateAsync({
          id: dataToUpdate?.id,
          data: { ...dataToUpdate, status: 'V' },
        })
        .then(() => {
          setIsSuccessModalVisible(true);
        });
    }

    if (confirmation && tags === 'deactivateAccount') {
      const dataToUpdate = {
        ...user,
        globalRole: user?.globalRole?.id,
        role: user?.roleId,
      };
      delete dataToUpdate?.globalRoleId;
      mutateEdit
        .mutateAsync({
          id: dataToUpdate?.id,
          data: { ...dataToUpdate, status: 'I' },
        })
        .then(() => {
          setIsSuccessModalVisible(true);
        });
    }
  };

  return (
    <React.Fragment>
      {mutateEdit.isLoading || isLoadingDeleteUser ? (
        <Loader />
      ) : (
        <ConfirmationModal
          openModal={isModalVisible}
          title={
            tags === 'activateAccount'
              ? t('access:Modal.confirmationValidation.title')
              : tags === 'deactivateAccount'
              ? t('access:Modal.confirmationDeactivation.title')
              : tags === 'deleteAccount'
              ? t('access:Modal.confirmationDelete.title')
              : null
          }
          description={
            tags === 'activateAccount'
              ? t('access:Modal.confirmationValidation.description')
              : tags === 'deactivateAccount'
              ? t('access:Modal.confirmationDeactivation.description')
              : tags === 'deleteAccount'
              ? t('access:Modal.confirmationDelete.description')
              : null
          }
          titleButton={
            tags === 'activateAccount'
              ? t('access:Modal.confirmationValidation.confirmationButton')
              : tags === 'deactivateAccount'
              ? t('access:Modal.confirmationDeactivation.confirmationButton')
              : tags === 'deleteAccount'
              ? t('access:Modal.confirmationDelete.confirmationButton')
              : null
          }
          cancelButton={t('common:cancel')}
          handleModalConfirmation={handleModalConfirmation}
          hideDialog={hideDialog}
        />
      )}
      <SuccessModal
        openModal={isSuccessModalVisible}
        title={
          tags === 'activateAccount'
            ? t('access:Modal.successValidation.title')
            : tags === 'deactivateAccount'
            ? t('access:Modal.successDeactivation.title')
            : tags === 'deleteAccount'
            ? t('access:Modal.successDelete.title')
            : null
        }
        description={
          tags === 'activateAccount'
            ? t('access:Modal.successValidation.description')
            : tags === 'deactivateAccount'
            ? t('access:Modal.successDeactivation.description')
            : tags === 'deleteAccount'
            ? t('access:Modal.successDelete.description')
            : null
        }
        cancelButton={t('common:cancel')}
        hideSuccessModal={hideSuccessModal}
      />
      <ListAppsModal
        openModal={isListAppsModalVisible}
        user={user}
        hideDialog={hideAppsListModal}
      />
      {data && data.data ? (
        <>
          <ContainerTable>
            <StyledDataGrid
              autoHeight={true}
              getRowHeight={() => 'auto'}
              rows={data.data || []}
              columns={columns}
              hideFooter={true}
              editMode="row"
              processRowUpdate={processRowUpdate}
              experimentalFeatures={{ newEditingApi: true }}
              components={{
                NoRowsOverlay: () => (
                  <TypographyDataGridEmtyRows
                    text={t('access:listPageTable.emptyTable')}
                  />
                ),
              }}
              checkboxSelection={false}
              disableSelectionOnClick
              disableColumnMenu
              disableColumnSelector
            />
          </ContainerTable>
          <Grid container justifyContent="flex-end">
            <Pagination
              mt={5}
              mb={5}
              count={Math.ceil(Number(data.meta.total) / 50)}
              page={paginationPage}
              onChange={handleChangePage}
              color="primary"
            />
          </Grid>
        </>
      ) : (
        <Grid container justifyContent={'center'} sx={{ mt: 15 }}>
          <Typography variant="h6" gutterBottom display="inline">
            {isLoading ? '' : t('access:listPageTable.emptyTable')}
          </Typography>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default AccessList;
