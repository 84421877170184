import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CardComponent, Loader, AppData } from '@exertis/template';

import DragAndDrop from './DragAndDrop';
import ApplicationByRoleFormMdoal from './ApplicationByRoleFormModal';
import {
  useAddRoleToApp,
  useDeleteAppByRole,
} from '../../hooks/application/useCrudApplicationByRole';

type visibleApplications = {
  id: string;
  appId: string;
  applicationName: string;
  applicationUrl: string;
  status: string;
};

type Props = {
  visibleApplications: visibleApplications[] | undefined;
  notVisibleApplications: AppData[] | undefined;
  isComboBoxSelected: boolean;
  selectedGlobalRoleValue: any;
  selectedRoleValue: any;
  allApps: any;
  setVisibleApplications(visibleApplications: visibleApplications[]): void;
  setNotVisibleApplications(notVisibleApplications: AppData[]): void;
  setIsFilterApplied(isFilterApplied: boolean): void;
  setIsFilterAppliedFinished(isFilterAppliedFinished: boolean): void;
};

const ApplicationByRoleList = (props: Props) => {
  /**
   * Translation
   */
  const { t } = useTranslation();

  /**
   * Init props
   */
  const {
    visibleApplications,
    notVisibleApplications,
    selectedGlobalRoleValue,
    selectedRoleValue,
    isComboBoxSelected,
    allApps,
    setIsFilterApplied,
    setIsFilterAppliedFinished,
    setVisibleApplications,
    setNotVisibleApplications,
  } = props;

  /**
   * Init states
   */
  const [applicationToEdit, setApplicationToEdit] = useState<AppData | null>(
    null
  );
  const [tag, setTag] = useState('');

  /**
   * Add role to application
   */
  const mutateAdd = useAddRoleToApp();

  /**
   * delete app by role
   */
  const { mutate: mutateDel } = useDeleteAppByRole();

  return (
    <>
      {mutateAdd.isLoading ? (
        <Loader />
      ) : (
        <>
          <ApplicationByRoleFormMdoal
            tag={tag}
            applicationToEdit={applicationToEdit}
            setApplicationToEdit={setApplicationToEdit}
            setIsFilterApplied={setIsFilterApplied}
            setIsFilterAppliedFinished={setIsFilterAppliedFinished}
            setTag={setTag}
          />
          <CardComponent title={t('appsByRole:listingPage.cardTitle')}>
            <DragAndDrop
              items={visibleApplications}
              selected={notVisibleApplications}
              isComboBoxSelected={isComboBoxSelected}
              selectedGlobalRoleValue={selectedGlobalRoleValue}
              selectedRoleValue={selectedRoleValue}
              mutateAdd={mutateAdd}
              allApps={allApps}
              setTag={setTag}
              mutateDel={mutateDel}
              setItems={setVisibleApplications}
              setSelected={setNotVisibleApplications}
              setApplicationToEdit={setApplicationToEdit}
              setIsFilterApplied={setIsFilterApplied}
              setIsFilterAppliedFinished={setIsFilterAppliedFinished}
            />
          </CardComponent>
        </>
      )}
    </>
  );
};

export default ApplicationByRoleList;
