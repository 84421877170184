import { useQuery } from '@tanstack/react-query';
import { httpApi, ApiRoleResponse, RoleType } from '@exertis/template';
import { compile } from 'path-to-regexp';

/**
 *
 * @param id api to get list of role
 * @returns
 */
export const getListRole = async (): Promise<ApiRoleResponse> => {
  return await httpApi.get('/roleparams');
};

/**
 * hook to call api to get list of role
 * @returns
 */
export function useGetListRole() {
  return useQuery(['role'], () => getListRole());
}

/**
 * api to get list of role by id
 * @param id
 * @returns
 */
export const getRoleById = async (id: string): Promise<RoleType> => {
  return await httpApi.get(
    compile('/roleparams/:id', { encode: encodeURIComponent })({ id })
  );
};

/**
 * hook to call api to get list of role by Id
 * @param id
 * @returns
 */
export function UseGetRoleById(id: string) {
  return useQuery(['role', id], () => getRoleById(id));
}
