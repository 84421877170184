import { RouteObject } from 'react-router-dom';
import { NewLayoutSideBar } from '@exertis/template';
import { NotFound, AccessDenied } from '@exertis/template';
import Access from '../pages/Access';
import AppsByRole from '../pages/AppsByRole';
import Dashboard from '../pages/Dashboard';
import RoleManagement from '../pages/RoleManagement';

const routes: RouteObject[] = [
  {
    path: '/',
    element: <NewLayoutSideBar />,
    children: [
      {
        path: '/',
        element: <Dashboard />,
        children: [
          {
            path: ':forbiddenApp',
            element: <Dashboard />,
          },
          {
            path: '',
            element: <Dashboard />,
          },
        ],
      },
      {
        path: 'apps-by-role',
        element: <AppsByRole />,
      },
      {
        path: 'access',
        element: <Access />,
      },
      {
        path: 'role-management',
        element: <RoleManagement />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
  {
    path: 'AccessDenied',
    element: <AccessDenied />,
  },
];

export default routes;
