import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Toast, useDebounce, HeaderPricing } from '@exertis/template';

import AccessFilter from '../components/access/AccessFilter';
import AccessList from '../components/access/AccessList';
import useGetLists from '../hooks/access/useGetLists';
import useDeleteUser from '../hooks/access/useDeleteUser';
import useEditAccess from '../hooks/access/useEditAccess';
import { useGetGlobalRoleList } from '../hooks/access/useGetGlobalRole';
import BackdropLoader from '../common/layouts/BackdropLoader';
import { useGetBusinessProfileList } from '../hooks/access/useGetBusinessProfile';
import { BusinessProfileType } from '../types/BusinessProfileTypes';

type ChipData = {
  key: number;
  label: string;
  value?: string;
  displayName?: string;
};

/**
 * Access list component
 *
 * @returns {JSX.Element}
 */
const Access = (): JSX.Element => {
  //Translation
  const { t } = useTranslation();

  /**
   * Global state
   * */
  const [paginationPage, setPaginationPage] = useState(1);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [chipData, setChipData] = useState<ChipData[]>([
    {
      key: 1,
      label: '',
    },
    {
      key: 2,
      label: '',
    },
    {
      key: 3,
      label: '',
    },
    {
      key: 4,
      label: '',
    },
    {
      key: 5,
      label: '',
    },
  ]);
  const [businessProfilePage, setBusinessProfilePage] = useState(1);
  const [searchByBusinessProfileName, setSearchByBusinessProfileName] =
    useState('');
  const [businessProfileOptions, setBusinessProfileOptions] = useState<
    BusinessProfileType[] | any
  >([]);

  // Hooks
  //Get list api
  const { isInitialLoading, data, error } = useGetLists(
    paginationPage,
    chipData.filter((chip: ChipData) => chip.key === 1)[0]?.label.trim(),
    chipData.filter((chip: ChipData) => chip.key === 2)[0]?.label.trim(),
    chipData.filter((chip: ChipData) => chip.key === 3)[0]?.label.trim(),
    chipData.filter((chip: ChipData) => chip.key === 4)[0]?.value?.trim(),
    chipData.filter((chip: ChipData) => chip.key === 5)[0]?.label?.trim()
  );

  /**
   * Init constant
   */
  const searchByBusinessProfileNameDebounce = useDebounce(
    searchByBusinessProfileName,
    500
  );
  // Get business profile list
  const {
    data: businessProfileList,
    isInitialLoading: isBusinessProfileLoading,
    refetch,
  } = useGetBusinessProfileList(
    businessProfilePage,
    searchByBusinessProfileNameDebounce
  );

  //Get globalRole list
  const { data: globalRoleList } = useGetGlobalRoleList();

  //Edit access
  const mutateEdit = useEditAccess();

  //Delete user api
  const { mutateAsync: mutateDel, isLoading: isLoadingDeleteUser } =
    useDeleteUser();

  /**
   * Get QueryClient from the context
   */
  const queryClient = useQueryClient();

  /**
   * Get language from local storage
   */
  const language = localStorage.getItem('language');

  /**
   * Hook to fill business profile state with businessProfile every time we call api for autocomplete
   */
  useEffect(() => {
    if (!isBusinessProfileLoading) {
      if (businessProfileList?.data) {
        const allData = [
          ...businessProfileOptions,
          ...businessProfileList?.data,
        ];
        let filteredData = allData.filter(
          (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
        );
        setBusinessProfileOptions(filteredData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBusinessProfileLoading, businessProfileList]);

  /**
   * Handle language change
   */
  useEffect(() => {
    if (language) {
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: ['globalRoleList'],
        });
        queryClient.invalidateQueries({
          queryKey: ['role'],
        });
        queryClient.invalidateQueries({
          queryKey: ['accessLists'],
        });
      }, 1000);
    }
    // eslint-disable-next-line
  }, [language]);

  /**
   * Reset page to 1 after search
   */
  useEffect(() => {
    if (isFilterApplied) {
      setPaginationPage(1);
      setIsFilterApplied(false);
    }
  }, [isFilterApplied]);

  useEffect(() => {
    if (data) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <HeaderPricing titlePage={t('access:header.title')} />
      {isInitialLoading && <BackdropLoader open={true} />}
      <>
        <AccessFilter
          chipData={chipData}
          globalRoleList={globalRoleList?.data.sort((a, b): any => {
            let textA = a.globalRoleName;
            let textB = b.globalRoleName;
            if (textA && textB) {
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            } else return undefined;
          })}
          setChipData={setChipData}
          setIsFilterApplied={setIsFilterApplied}
        />
        <AccessList
          data={data}
          paginationPage={paginationPage}
          mutateEdit={mutateEdit}
          isLoadingDeleteUser={isLoadingDeleteUser}
          isLoading={isInitialLoading}
          businessProfileOptions={businessProfileOptions}
          isBusinessProfileLoading={isBusinessProfileLoading}
          setSearchByBusinessProfileName={setSearchByBusinessProfileName}
          setBusinessProfilePage={setBusinessProfilePage}
          deleteUser={mutateDel}
          setPaginationPage={setPaginationPage}
        />
      </>
      {error && Toast({ title: 'Cannot get access', method: 'error' })}
    </>
  );
};
export default Access;
