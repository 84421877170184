import { useTranslation } from 'react-i18next';
import { httpApi, ApiError, useAppMutation, Toast } from '@exertis/template';

export function useGenerateApiKey() {
  const { t } = useTranslation();
  return useAppMutation(
    ['apiKey'],
    async (id: string) => await httpApi.post(`/accesses/${id}/api-key`, {}),
    {
      onPostSuccess: () => {
        Toast({
          title: t('access:apiResponse.apiKeyGenerated'),
          method: 'success',
        });
      },
      onPostError: (error: ApiError) => {
        if (error?.message) {
          Toast({
            title: error.message,
            method: 'error',
          });
        }
        if (error?.errors) {
          error?.errors.map((err) =>
            Toast({
              title: err.message,
              method: 'error',
            })
          );
        }
      },
    }
  );
}
