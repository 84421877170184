import { useTranslation } from 'react-i18next';
import {
  useQuery,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import {
  httpApi,
  ApiError,
  useAppMutation,
  Toast,
  useModalStore,
  HeaderAppByRole,
  HeaderAppsType,
} from '@exertis/template';
import { compile } from 'path-to-regexp';

/**
 * hook to call api to get list of application by role
 * @returns
 */
export function useGetApplicationByRole(
  globalRoleId: string | undefined,
  roleId: string | undefined,
  isFilterApplied: boolean
) {
  const url = `/appbyroles?filter[globalRole][eq]=${globalRoleId}&filter[role][eq]=${roleId}&expand=app,globalRole,role&limit=100`;
  return useQuery(
    ['applicationsByRoleList', globalRoleId, roleId, isFilterApplied],
    async (): Promise<HeaderAppByRole> => await httpApi.get(url),
    {
      enabled: !!globalRoleId && !!roleId && !!isFilterApplied,
      onError(error: ApiError) {
        if (error?.message) {
          Toast({
            title: error.message,
            method: 'error',
          });
        }
        if (error?.errors) {
          error?.errors.map((err: any) =>
            Toast({
              title: err.message,
              method: 'error',
            })
          );
        }
      },
    }
  );
}

/**
 * add Role to application
 * @returns
 */
export function useAddRoleToApp(): UseMutationResult<any, ApiError> {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useAppMutation(
    ['applicationsByRoleList'],
    async (data: any) => await httpApi.post('/appbyroles', data),
    {
      onPostSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['applicationsByRoleList'] });
        Toast({
          title: t(
            'appsByRole:listingPage.formModal.apiResponse.applicationAdded'
          ),
          method: 'success',
        });
      },
      onPostError: (error: ApiError) => {
        if (error?.message) {
          Toast({
            title: error.message,
            method: 'error',
          });
        }
        if (error?.errors) {
          error?.errors.map((err) =>
            Toast({
              title: err.message,
              method: 'error',
            })
          );
        }
      },
    }
  );
}

/**
 * API to edit application by Role
 * @param id
 * @param data
 * @returns
 */
const editApplicationByRole = async (
  id: string,
  data: HeaderAppsType
): Promise<HeaderAppsType[]> => {
  return await httpApi.patch(
    compile('/appbyroles/:id', { encode: encodeURIComponent })({ id }),
    data
  );
};

/**
 * hook to call api edit application By Role
 * @returns
 */
export function useEditAppsByRole(): UseMutationResult<
  any,
  ApiError,
  any,
  any
> {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useAppMutation(
    ['applicationsByRoleList'],
    ({ id, data }: { id: string; data: HeaderAppsType }) =>
      editApplicationByRole(id, data),
    {
      onPostSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['applicationsByRoleList'] });
        queryClient.invalidateQueries({ queryKey: ['applicationsList'] });
        Toast({
          title: t(
            'appsByRole:listingPage.formModal.apiResponse.applicationEdited'
          ),
          method: 'success',
        });
      },
      onPostError: (error: ApiError) => {
        if (error?.message) {
          Toast({
            title: error.message,
            method: 'error',
          });
        }
        if (error?.errors) {
          error?.errors.map((err) =>
            Toast({
              title: err.message,
              method: 'error',
            })
          );
        }
      },
    }
  );
}

/**
 * Hook to call api to delete app by role
 * @returns
 */
export function useDeleteAppByRole() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { hideModal } = useModalStore();
  return useAppMutation(
    ['applicationsByRoleList'],
    async (id: number): Promise<void> =>
      await httpApi.delete(
        compile('/appbyroles/:id', { encode: encodeURIComponent })({ id })
      ),
    {
      onPostSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['applicationsByRoleList'] });
        queryClient.invalidateQueries({ queryKey: ['applicationsList'] });
        hideModal();
        Toast({
          title: t(
            'appsByRole:listingPage.formModal.apiResponse.applicationDeleted'
          ),
          method: 'success',
        });
      },
      onPostError: (error: ApiError) => {
        if (error?.message) {
          Toast({
            title: error.message,
            method: 'error',
          });
        }
        if (error?.errors) {
          error?.errors.map((err) =>
            Toast({
              title: err.message,
              method: 'error',
            })
          );
        }
      },
    }
  );
}

/**
 * hook to call api to get list apps by role enabled
 * @param id
 * @returns
 */
export function useGetAppsByRoleEnabled(
  globalRoleName: string,
  roleName: string
) {
  return useQuery(
    ['appsByRoleEnabled', globalRoleName, roleName],
    async (): Promise<HeaderAppByRole> =>
      await httpApi.get(
        `/appbyroles?filter[globalRole.globalRoleName][eq]=${globalRoleName}&filter[role.roleName][eq]=${roleName}&filter[app.status][eq]=enabled&expand=globalRole,role,app`
      ),
    {
      onError(error: ApiError) {
        if (error?.message) {
          Toast({
            title: error.message,
            method: 'error',
          });
        }
        if (error?.errors) {
          error?.errors.map((err: any) =>
            Toast({
              title: err.message,
              method: 'error',
            })
          );
        }
      },
    }
  );
}

/**
 * hook to call api to get list apps by role disabled
 * @param id
 * @returns
 */
export function useGetAppsByRoleDisabled(
  globalRoleName: string,
  roleName: string
) {
  return useQuery(
    ['appsByGlobalDisabled', globalRoleName, roleName],
    async (): Promise<HeaderAppByRole> =>
      await httpApi.get(
        `/appbyroles?filter[globalRole.globalRoleName][eq]=${globalRoleName}&filter[role.roleName][eq]=${roleName}&filter[app.status][eq]=disabled&expand=globalRole,role,app`
      ),
    {
      onError(error: ApiError) {
        if (error?.message) {
          Toast({
            title: error.message,
            method: 'error',
          });
        }
        if (error?.errors) {
          error?.errors.map((err: any) =>
            Toast({
              title: err.message,
              method: 'error',
            })
          );
        }
      },
    }
  );
}

/**
 * hook to call api to get list apps by role enabled
 * @param id
 * @returns
 */
export function GetAppsByRoleEnabledWhenOpenModal(
  globalRoleName: string | undefined,
  roleName: string | undefined,
  openModal: boolean
) {
  return useQuery(
    ['appsByRoleEnabled', globalRoleName, roleName, openModal],
    async (): Promise<HeaderAppByRole> =>
      await httpApi.get(
        `/appbyroles?filter[globalRole.globalRoleName][eq]=${globalRoleName}&filter[role.roleName][eq]=${roleName}&filter[app.status][eq]=enabled&expand=globalRole,role,app`
      ),
    {
      enabled: openModal,
      onError(error: ApiError) {
        if (error?.message) {
          Toast({
            title: error.message,
            method: 'error',
          });
        }
        if (error?.errors) {
          error?.errors.map((err: any) =>
            Toast({
              title: err.message,
              method: 'error',
            })
          );
        }
      },
    }
  );
}
