import { useQuery, useQueries } from '@tanstack/react-query';
import { httpApi } from '@exertis/template';

import config from '../config';
/**
 * Get statistic sorted hook
 * @returns
 */
export function useGetStatisticSorted(idApp: string | undefined) {
  return useQuery(
    ['stat-dashboard', idApp],
    async (): Promise<any> =>
      await httpApi.get(
        `${config.statisticApiBaseUrl}/dashboard?idApp=${idApp}`
      )
  );
}

/**
 * Get statistics queries
 * @param statisticIds
 * @returns
 */
export function useGetStatisticQueries(statisticIds: string[]) {
  return useQueries({
    queries: statisticIds.map((item) => ({
      queryKey: ['stat-query', item],
      queryFn: async (): Promise<any> => {
        return await httpApi.get(
          `${config.statisticApiBaseUrl}/statistics/${item}/data`
        );
      },
      refetchOnWindowFocus: false,
    })),
  });
}
