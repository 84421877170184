import { Loader, closeIcon, HeaderAppsType } from '@exertis/template';

import { Dialog, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { GetAppsByRoleEnabledWhenOpenModal } from '../../hooks/application/useCrudApplicationByRole';

/**
 * Styled component
 */
const BoxContent = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  paddingTop: 10,
  background: '#F7F7F7',
  height: 180,
  width: 160,
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
  borderRadius: 15,
});

const CloseIconModal = styled('img')({
  width: 20,
  height: 20,
  marginTop: 10,
  marginRight: 10,
  zIndex: 1,
  cursor: 'pointer',
});

const Logo = styled('img')({
  width: 132,
  height: 132,
});

/**
 * Init props type
 *
 */
type Props = {
  openModal: boolean;
  user: HeaderAppsType;
  hideDialog(): void;
};

const ListAppsModal = (props: Props) => {
  /**
   * Init props
   */
  const { openModal, user, hideDialog } = props;

  /**
   * Api calls
   */
  const { data: appsAssociateToRole, isInitialLoading } =
    GetAppsByRoleEnabledWhenOpenModal(
      user.globalRole?.globalRoleName,
      user?.role?.roleName,
      openModal
    );

  /**
   * Return TSX content
   */
  return (
    <Dialog
      open={openModal}
      PaperProps={{
        sx: {
          width: '100%',
          height: isInitialLoading ? 300 : 'auto',
          borderRadius: '4px',
          overflowX: 'hidden',
        },
      }}
    >
      <Grid container item direction="row-reverse">
        <CloseIconModal
          onClick={() => {
            hideDialog();
          }}
          src={closeIcon()}
        />
      </Grid>
      {isInitialLoading ? (
        <Loader />
      ) : (
        <Grid container spacing={3} mt={10} mb={10} justifyContent="center">
          {appsAssociateToRole?.data.map((apps, index: number) => (
            <Grid item>
              <BoxContent key={index}>
                <Logo
                  src={
                    apps?.app?.logoApplicationImg
                      ? apps.app.logoApplicationImg
                      : ''
                  }
                  alt={apps?.app?.applicationName}
                />
              </BoxContent>
            </Grid>
          ))}
        </Grid>
      )}
    </Dialog>
  );
};

export default ListAppsModal;
