import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useModalStore,
  Toast,
  useAuthStore,
  AppData,
  threePointIcon,
  addAppPicture,
  dragAndDropPicture,
} from '@exertis/template';

import {
  Grid,
  Typography as MuiTypography,
  useTheme,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import { styled, spacing } from '@mui/system';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableLocation,
  DroppableStateSnapshot,
  DraggableProvided,
  DroppableProvided,
  DraggableStateSnapshot,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd';

import { ApplicationByRole } from '../../types/appsByRoleTypes';
import exertisLogoGrey from '../../assets/exertis-logo-grey.svg';
import exertisLogoRed from '../../assets/exertis-logo-red.svg';

//styled component
const Typography = styled(MuiTypography)(spacing);

const StyledGridCard = styled(Grid)({
  userSelect: 'none',
  padding: 16,
  margin: `0 40px 15px 40px`,
  width: 218,
  height: 269,
  border: '1px dashed #9E9E9E',
  borderRadius: 4,
  background: 'transparent',
});

const DefaultLogo = styled('img')({
  width: 132,
  height: 30,
});
const Logo = styled('img')({
  width: 132,
  height: 132,
});

const LogoDragAndAdd = styled('img')({
  width: 50,
  height: 50,
});

const StyledIconButton = styled(IconButton)((props: any) => ({
  left: 10,
  top: -10,
  zIndex: 1,
  color: `${props.theme.palette.grey[500]}`,
}));

const IconThreePoint = styled('img')({
  width: 15,
  height: 15,
});

interface IMoveResult {
  droppable: any[];
  droppable2: any[];
}

const grid: number = 8;

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
): React.CSSProperties => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: 2 * grid,
  margin: `0 40px 15px 40px`,
  width: 218,
  height: 269,
  borderRadius: 4,
  // change background colour if dragging
  background: isDragging ? 'lightgreen' : '#FFFFFF',
  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
  background: isDraggingOver ? 'lightblue' : '#f9f9f9',
  padding: grid,
  width: '100%',
  display: 'flex',
  marginBottom: 50,
});

//init props component
type Props = {
  items: any;
  selected: any;
  isComboBoxSelected: boolean;
  selectedGlobalRoleValue: any;
  selectedRoleValue: any;
  mutateAdd?: any;
  mutateDel?: any;
  allApps: any;
  setTag(tag: string): void;
  setItems(items: any): void;
  setSelected(selected: any): void;
  setApplicationToEdit(applicationToEdit: AppData): void;
  setIsFilterApplied(isFilterApplied: boolean): void;
  setIsFilterAppliedFinished(isFilterAppliedFinished: boolean): void;
};

const DragAndDrop = (props: Props) => {
  const { showModal } = useModalStore();
  const { me } = useAuthStore();

  /**
   * Translation
   */
  const { t } = useTranslation();

  /**
   * Init props
   */
  const {
    items,
    selected,
    isComboBoxSelected,
    selectedGlobalRoleValue,
    selectedRoleValue,
    mutateAdd,
    mutateDel,
    allApps,
    setTag,
    setItems,
    setSelected,
    setApplicationToEdit,
    setIsFilterApplied,
    setIsFilterAppliedFinished,
  } = props;

  //getBreakpoint min 600px to change drag and drop to vertical direction
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  //States
  const [isBlockClicked, setIsBlockClicked] = useState(false);

  /**
   * Moves an item from one list to another list.
   */
  const move = (
    source: any[],
    destination: any[],
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation
  ): IMoveResult | any => {
    const sourceClone = [...source];
    const destClone = [...destination];
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    if (droppableSource.droppableId === 'droppable2') {
      let addRoleToApplication: ApplicationByRole = {
        globalRole: selectedGlobalRoleValue.id,
        role: selectedRoleValue.id,
        app: removed.id,
      };

      mutateAdd.mutateAsync(addRoleToApplication).then((result: any) => {
        const toAdd = {
          appId: result.appId,
          applicationName: removed.applicationName,
          applicationUrl: removed.applicationUrl,
          id: result.id,
          logoApplicationImg: removed.logoApplicationImg,
          status: 'enabled',
        };

        destClone.splice(droppableDestination.index, 0, toAdd);
      });
    }

    if (droppableSource.droppableId === 'droppable') {
      mutateDel(removed.id);
      const addToDestApp = allApps.filter(
        (app: any) => removed.appId === app?.id
      );
      destClone.splice(droppableDestination.index, 0, addToDestApp[0]);
    }

    const result: any = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const getList = (id: string): any => {
    if (id === 'droppable2') {
      return selected;
    } else {
      return items;
    }
  };

  /**
   *
   * Finish drag
   * @returns
   */
  const onDragEnd = (result: any) => {
    const { source, destination } = result;
    if (!isComboBoxSelected && isBlockClicked) {
      Toast({
        title: t('appsByRole:listingPage.warningMessage'),
        method: 'warning',
      });
      setIsBlockClicked(false);
    } else {
      // dropped outside the list
      if (!destination) {
        return;
      }

      if (source.droppableId === destination.droppableId) {
        if (source.droppableId === 'droppable2') {
          setSelected((prevSelected: any) => {
            const selected = Array.from(prevSelected);
            const [removed] = selected.splice(source.index, 1);
            selected.splice(destination.index, 0, removed);
            return selected;
          });
        } else if (source.droppableId === 'droppable') {
          setItems((prevItems: any) => {
            const items = Array.from(prevItems);
            const [removed] = items.splice(source.index, 1);
            items.splice(destination.index, 0, removed);
            return items;
          });
        }
      } else {
        const result: IMoveResult = move(
          getList(source.droppableId),
          getList(destination.droppableId),
          source,
          destination
        );

        setItems(result.droppable);
        setSelected(result.droppable2);
      }
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Typography variant="h6" ml={13} mb={7}>
        {t('appsByRole:listingPage.appsVisible')}
      </Typography>
      <Droppable
        droppableId="droppable"
        direction={smDown ? 'vertical' : 'horizontal'}
        isDropDisabled={
          !isComboBoxSelected &&
          me?.globalRole.globalRoleName === 'collaborateurs' &&
          me?.role.roleName === 'it'
        }
      >
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
          <Grid
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={getListStyle(snapshot.isDraggingOver)}
            justifyContent="flex-start"
            flexWrap={'wrap'}
            onMouseOver={() => !isComboBoxSelected && setIsBlockClicked(true)}
          >
            {items &&
              items.map((item: any, index: number) => {
                return (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(
                      providedDraggable: DraggableProvided,
                      snapshotDraggable: DraggableStateSnapshot
                    ) => (
                      <Grid
                        item
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                        style={getItemStyle(
                          snapshotDraggable.isDragging,
                          providedDraggable.draggableProps.style
                        )}
                        xs={12}
                        md={3}
                        lg={2}
                      >
                        <Grid container item direction="row-reverse">
                          <StyledIconButton
                            onClick={() => {
                              setApplicationToEdit(item);
                              showModal();
                              setTag('editVisibleApps');
                              setIsFilterApplied(true);
                              setIsFilterAppliedFinished(false);
                            }}
                          >
                            <IconThreePoint src={threePointIcon()} alt="" />
                          </StyledIconButton>
                        </Grid>
                        <Grid
                          container
                          justifyContent={'center'}
                          alignItems="center"
                          direction={'column'}
                          sx={{
                            mt:
                              item.logoApplicationImg ||
                              item.logoApplicationDisabledImg
                                ? 3
                                : 15,
                          }}
                        >
                          {item.logoApplicationImg &&
                          item.status === 'enabled' ? (
                            <Logo
                              src={item.logoApplicationImg}
                              alt={item.applicationName}
                            />
                          ) : item.logoApplicationDisabledImg &&
                            item.status === 'disabled' ? (
                            <Logo
                              src={item.logoApplicationDisabledImg}
                              alt={item.applicationName}
                            />
                          ) : item.status === 'disabled' &&
                            !item.logoApplicationDisabledImg &&
                            item.logoApplicationImg ? (
                            <Logo
                              src={item.logoApplicationImg}
                              alt={item.applicationName}
                            />
                          ) : (
                            <DefaultLogo src={exertisLogoRed} alt="" />
                          )}
                          <Typography
                            variant="h6"
                            mt={
                              item.logoApplicationImg ||
                              item.logoApplicationDisabledImg
                                ? 3
                                : 16.5
                            }
                            style={{ color: '#9E9E9E', fontSize: 14 }}
                          >
                            {item.applicationName}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Draggable>
                );
              })}
            {provided.placeholder}
            <StyledGridCard container>
              <Grid
                container
                justifyContent={'center'}
                alignItems="center"
                direction={'column'}
              >
                <LogoDragAndAdd src={dragAndDropPicture()} alt="" />
                <Typography
                  variant="h4"
                  mt={5}
                  style={{ color: '#9E9E9E', fontSize: 24 }}
                >
                  {t('appsByRole:listingPage.plceholderDragAndDrop')}
                </Typography>
              </Grid>
            </StyledGridCard>
          </Grid>
        )}
      </Droppable>
      <Typography variant="h6" ml={13} mb={7}>
        {t('appsByRole:listingPage.appsNotVisible')}
      </Typography>
      <Droppable
        droppableId="droppable2"
        direction={smDown ? 'vertical' : 'horizontal'}
        isDropDisabled={
          !isComboBoxSelected &&
          me?.globalRole.globalRoleName === 'collaborateurs' &&
          me?.role.roleName === 'it'
        }
      >
        {(provided, snapshot) => (
          <Grid
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
            justifyContent="flex-start"
            flexWrap={'wrap'}
            onClick={() => {
              !isComboBoxSelected && setIsBlockClicked(true);
            }}
          >
            {selected &&
              selected.map((item: any, index: number) => {
                return (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(
                      providedDraggable: DraggableProvided,
                      snapshotDraggable: DraggableStateSnapshot
                    ) => (
                      <Grid
                        item
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                        style={getItemStyle(
                          snapshotDraggable.isDragging,
                          providedDraggable.draggableProps.style
                        )}
                        xs={12}
                        md={3}
                        lg={2}
                      >
                        <Grid container item direction="row-reverse">
                          <StyledIconButton
                            onClick={() => {
                              setApplicationToEdit(item);
                              showModal();
                              setTag('editApps');
                              setIsFilterApplied(true);
                              setIsFilterAppliedFinished(false);
                            }}
                          >
                            <IconThreePoint src={threePointIcon()} alt="" />
                          </StyledIconButton>
                        </Grid>
                        <Grid
                          container
                          justifyContent={'center'}
                          alignItems="center"
                          direction={'column'}
                          sx={{
                            mt:
                              item.logoApplicationImg ||
                              item.logoApplicationDisabledImg
                                ? 3
                                : 15,
                          }}
                        >
                          {item.logoApplicationImg &&
                          item.status === 'enabled' ? (
                            <Logo
                              src={item.logoApplicationImg}
                              alt={item.applicationName}
                            />
                          ) : item.logoApplicationDisabledImg &&
                            item.status === 'disabled' ? (
                            <Logo
                              src={item.logoApplicationDisabledImg}
                              alt={item.applicationName}
                            />
                          ) : item.status === 'disabled' &&
                            !item.logoApplicationDisabledImg &&
                            item.logoApplicationImg ? (
                            <Logo
                              src={item.logoApplicationImg}
                              alt={item.applicationName}
                            />
                          ) : (
                            <DefaultLogo src={exertisLogoGrey} alt="" />
                          )}
                          <Typography
                            variant="h6"
                            mt={
                              item.logoApplicationImg ||
                              item.logoApplicationDisabledImg
                                ? 3
                                : 16
                            }
                            style={{ color: '#9E9E9E', fontSize: 14 }}
                          >
                            {item.applicationName}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Draggable>
                );
              })}
            {provided.placeholder}
            <StyledGridCard container>
              <Grid
                container
                justifyContent={'center'}
                alignItems="center"
                direction={'column'}
              >
                <LogoDragAndAdd src={dragAndDropPicture()} alt="" />
                <Typography
                  variant="h4"
                  mt={5}
                  style={{ color: '#9E9E9E', fontSize: 24 }}
                >
                  {t('appsByRole:listingPage.plceholderDragAndDrop')}
                </Typography>
              </Grid>
            </StyledGridCard>
            <StyledGridCard
              container
              onClick={() => {
                showModal();
                setTag('createApps');
              }}
            >
              <Grid
                container
                justifyContent={'center'}
                alignItems="center"
                direction={'column'}
                sx={{
                  mt: 10,
                }}
              >
                <LogoDragAndAdd src={addAppPicture()} alt="" />
                <Typography
                  variant="h4"
                  mt={5}
                  style={{
                    color: '#9E9E9E',
                    fontSize: 24,
                    textAlign: 'center',
                  }}
                >
                  {t('appsByRole:listingPage.createApplication')}
                </Typography>
              </Grid>
            </StyledGridCard>
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
};
export default DragAndDrop;
