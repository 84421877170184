import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BackdropLoader,
  BlackTypography,
  PrimaryButton,
} from '@exertis/template';

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper as muiPapper,
  Checkbox,
} from '@mui/material';
import { styled } from '@mui/system';

import { BusinessProfileType } from '../../types/BusinessProfileTypes';
import { ApiFeatureResponse, FeatureType } from '../../types/FeatureTypes';
import { AssociateBusinessProfileFeatures } from '../../hooks/businessProfile/useGetBusinessProfile';

/**
 * Styled components
 */
const Paper = styled(muiPapper)({
  height: 700,
});

/**
 * Type props
 */
type Props = {
  businessProfileList: BusinessProfileType[] | undefined;
  featureList: ApiFeatureResponse | undefined;
  appId: string | null;
};
let businessProfiles: any = {};
const RoleManagementList = (props: Props) => {
  /**
   * Translation
   */
  const { t } = useTranslation();

  /**
   * Init props
   */
  const { businessProfileList, featureList, appId } = props;

  /**
   * Init states
   */
  const [associationList, setAssociationList] = useState<any>();

  /**
   * Associtae business profile feature api
   */
  const mutateAssociateFeatures = AssociateBusinessProfileFeatures();

  /**
   * Handle checkbox change
   */
  const handleSubmitChanges = () => {
    const dataToSend = {
      appId: appId,
      association: associationList,
    };

    mutateAssociateFeatures.mutateAsync(dataToSend);
  };

  /**
   * Init business profile data
   */
  useEffect(() => {
    if (businessProfileList) {
      Object.entries(businessProfileList).map(([key, value]) => {
        businessProfiles[value.id] = value.features?.map(
          (item: any) => item.id
        );
      });
      setAssociationList(businessProfiles);
    }
  }, [businessProfileList]);

  /**
   * Handle checkbox changes
   */
  const handleCheckBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    featureId: string,
    businessId: any
  ) => {
    if (event.target.checked) {
      businessProfiles[businessId] = [
        ...businessProfiles[businessId],
        featureId,
      ];
      setAssociationList(businessProfiles);
    } else {
      businessProfiles[businessId] = businessProfiles[businessId].filter(
        (item: string) => item !== featureId
      );
      setAssociationList(businessProfiles);
    }
  };

  /**
   * Return tsx content
   */
  return (
    <Grid>
      <BackdropLoader open={mutateAssociateFeatures.isLoading} />
      {businessProfileList && featureList && featureList.data.length > 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center"></TableCell>
                  {businessProfileList.map(
                    (item: BusinessProfileType | undefined) => (
                      <TableCell align="center" key={item?.id + 'bp'}>
                        <BlackTypography
                          style={{ fontSize: 12, textAlign: 'center' }}
                        >
                          {item?.name}
                        </BlackTypography>
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {featureList.data.map((row: FeatureType) => (
                  <TableRow key={row.id + 'f'}>
                    <TableCell align="center" width={'20%'}>
                      <BlackTypography
                        style={{ fontSize: 12, textAlign: 'center' }}
                      >
                        {row.name}
                      </BlackTypography>
                    </TableCell>
                    {businessProfileList.map(
                      (item: BusinessProfileType, index: number) => (
                        <TableCell align="center" key={index + item?.id}>
                          <Checkbox
                            defaultChecked={item?.features?.some(
                              (element: FeatureType) => element.id === row.id
                            )}
                            onChange={(event) => {
                              handleCheckBoxChange(event, row.id, item?.id);
                            }}
                          />
                        </TableCell>
                      )
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <PrimaryButton
            typography={t('roleManagement:roleManagementList.validate')}
            style={{ float: 'right', marginTop: 10, marginRight: 5 }}
            onClick={handleSubmitChanges}
          />
        </>
      ) : (
        <Grid container justifyContent={'center'} sx={{ mt: 15 }}>
          <BlackTypography gutterBottom display="inline">
            {t('access:listPageTable.emptyTable')}
          </BlackTypography>
        </Grid>
      )}
    </Grid>
  );
};

export default RoleManagementList;
