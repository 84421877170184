import { useQuery } from '@tanstack/react-query';
import { ApiError, Toast, httpApi } from '@exertis/template';

import { ApiBusinessProfileResponse } from '../../types/BusinessProfileTypes';

export function useGetBusinessProfileList(page: number, searchValue?: string) {
  let url = `/business-profile-params?page=${page}&limit=20`;
  if (searchValue) {
    url += `filter[name][like]=${searchValue}`;
  }
  return useQuery(
    ['businessProfile', page, searchValue],
    async (): Promise<ApiBusinessProfileResponse> => await httpApi.get(url),
    {
      enabled: false,
      onSuccess() {},
      onError(error: ApiError) {
        if (error?.message) {
          Toast({
            title: error.message,
            method: 'error',
          });
        }
      },
    }
  );
}
