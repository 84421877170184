import { Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  anchorEl: any;
  user: any;
  mutateGenerateApiKey:any;
  handleCloseMenu(): void;
  showDialog(): void;
  showAppsListModal(): void;
  setTags(tags: string): void;
};
const MenuItemsComponent = (props: Props) => {
  //Translation
  const { t } = useTranslation();

  //Init props
  const {
    anchorEl,
    user,
    mutateGenerateApiKey,
    handleCloseMenu,
    showDialog,
    showAppsListModal,
    setTags,
  } = props;

  return (
    <>
      <Menu
        key={user.id}
        sx={{
          borderRadius: 4,
          width: 'auto',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {user.status === 'A' && (
          <MenuItem
            onClick={() => {
              showDialog();
              setTags('activateAccount');
            }}
          >
            {t('access:listPageTable.menuItem.activation')}
          </MenuItem>
        )}
        {user.status === 'A' && (
          <MenuItem
            onClick={() => {
              showDialog();
              setTags('deleteAccount');
            }}
          >
            {t('access:listPageTable.menuItem.delete')}
          </MenuItem>
        )}
        {user.status === 'V' && (
          <MenuItem
            onClick={() => {
              showDialog();
              setTags('deactivateAccount');
            }}
          >
            {t('access:listPageTable.menuItem.deactivation')}
          </MenuItem>
        )}
        {user.status === 'I' && (
          <MenuItem
            onClick={() => {
              showDialog();
              setTags('activateAccount');
            }}
          >
            {t('access:listPageTable.menuItem.activation')}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            showAppsListModal();
          }}
        >
          {t('access:listPageTable.menuItem.displayAppsAssociate')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            mutateGenerateApiKey.mutateAsync(user.id);
            handleCloseMenu();
          }}
        >
         {t('access:listPageTable.menuItem.generateApiKey')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default MenuItemsComponent;
