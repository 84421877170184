import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderPricing } from '@exertis/template';

import BackdropLoader from '../common/layouts/BackdropLoader';
import { useGetBusinessProfiles } from '../hooks/businessProfile/useGetBusinessProfile';
import { useGetFeatures } from '../hooks/feature/useGetFeatures';
import { useGetApplications } from '../hooks/application/useCrudApplications';
import RoleManagementFilter from '../components/RoleManagement/RoleManagementFilter';
import RoleManagementList from '../components/RoleManagement/RoleManagementList';

const RoleManagement = () => {
  /**
   * Translation
   */
  const { t } = useTranslation();

  const [selectedApp, setSelectedApp] = useState<string | null>(null);

  /**
   * Business profile api
   */
  const {
    data: businessProfileList,
    isInitialLoading: isBusinessProfileLoading,
  } = useGetBusinessProfiles();

  /**
   * Feature api
   */
  const { data: featureList, isInitialLoading: isFeatureLoading } =
    useGetFeatures(selectedApp);

  /**
   * App api
   */
  const { data: applicationList, isInitialLoading: isApplicationsLoading } =
    useGetApplications();

  return (
    <>
      <HeaderPricing titlePage={t('roleManagement:header.title')} />
      <BackdropLoader
        open={
          isBusinessProfileLoading || isFeatureLoading || isApplicationsLoading
        }
      />
      <RoleManagementFilter
        appList={applicationList?.data}
        selectedApp={selectedApp || ''}
        setSelectedApp={setSelectedApp}
      />
      <RoleManagementList
        businessProfileList={businessProfileList?.data}
        featureList={featureList}
        appId={selectedApp}
      />
    </>
  );
};

export default RoleManagement;
