import { useTranslation } from 'react-i18next';
import {
  useQuery,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import {
  httpApi,
  ApiError,
  useAppMutation,
  useModalStore,
  Toast,
  ListApplication,
  AppData,
  useAuthStore,
  User,
} from '@exertis/template';

/**
 * hook to call all applications
 * @returns
 */
export function useGetApplications() {
  const url = `/apps?expand=app`;

  return useQuery(
    ['applicationsList'],
    async (): Promise<ListApplication> => await httpApi.get(url),
    {
      onError(error: ApiError) {
        if (error?.message) {
          Toast({
            title: error.message,
            method: 'error',
          });
        }
        if (error?.errors) {
          error?.errors.map((err: any) =>
            Toast({
              title: err.message,
              method: 'error',
            })
          );
        }
      },
    }
  );
}

/**
 * Add application
 * @returns
 */
export function useAddApplication(
  setPictureToShow: any,
  setPictureToShowDisabled: any
): UseMutationResult<any, ApiError> {
  const { t } = useTranslation();
  const { hideModal } = useModalStore();
  const { setMe } = useAuthStore();
  return useAppMutation(
    ['applicationsList'],
    async (data: AppData) =>
      await httpApi.post('/apps', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    {
      onPostSuccess: async () => {
        hideModal();
        Toast({
          title: t(
            'appsByRole:listingPage.formModal.apiResponse.applicationAdded'
          ),
          method: 'success',
        });
        setPictureToShow(null);
        setPictureToShowDisabled(null);
        const me = await httpApi.get<User>('/me');
        setMe(me);
      },
      onPostError: (error: ApiError) => {
        if (error?.message) {
          Toast({
            title: error.message,
            method: 'error',
          });
        }
        if (error?.errors) {
          error?.errors.map((err) =>
            Toast({
              title: err.message,
              method: 'error',
            })
          );
        }
      },
    }
  );
}

/**
 * hook to call api edit application
 * @returns
 */
export function useEditApplication(
  setPictureToShow: any,
  setPictureToShowDisabled: any,
  setApplicationToEdit: any
): UseMutationResult<any, ApiError, any, any> {
  const { t } = useTranslation();
  const { hideModal } = useModalStore();
  const { setMe } = useAuthStore();
  const queryClient = useQueryClient();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useAppMutation(
    ['applicationsList', 'applicationList'],
    async ({ id, data }: { id: string; data: AppData }) =>
      await httpApi.post(`/apps/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    {
      onPostSuccess: async () => {
        hideModal();
        queryClient.invalidateQueries({ queryKey: ['applicationsByRoleList'] });
        queryClient.invalidateQueries({ queryKey: ['applicationsList'] });
        Toast({
          title: t(
            'appsByRole:listingPage.formModal.apiResponse.applicationEdited'
          ),
          method: 'success',
        });
        setPictureToShow(null);
        setPictureToShowDisabled(null);
        setApplicationToEdit(null);
        const me = await httpApi.get<User>('/me');
        setMe(me);
      },
      onPostError: (error: ApiError) => {
        if (error?.message) {
          Toast({
            title: error.message,
            method: 'error',
          });
        }
        if (error?.errors) {
          error?.errors.map((err) =>
            Toast({
              title: err.message,
              method: 'error',
            })
          );
        }
      },
    }
  );
}
