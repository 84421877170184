import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  useAuthStore,
  SeparatorComponent,
  DashboardProfile,
  DashboardStats,
  HeaderPricing,
} from '@exertis/template';

import DashboardListing from '../components/dashboard/DashboardListing';
import {
  useGetAppsByRoleEnabled,
  useGetAppsByRoleDisabled,
} from '../hooks/application/useCrudApplicationByRole';
import useEditAccess from '../hooks/access/useEditAccess';
import { useGetRandomAdvertisement } from '../hooks/advertisement';
import {
  useGetStatisticQueries,
  useGetStatisticSorted,
} from '../hooks/statistic';
import { idApp } from '../constants/appConfig';
import config from '../config';
import BackdropLoader from '../common/layouts/BackdropLoader';
import DeniedAccessAppWarning from '../components/dashboard/DeniedAccessAppWarning';

const Dashboard = (): JSX.Element => {
  const { me } = useAuthStore();

  const { t } = useTranslation();

  const { forbiddenApp } = useParams();

  /**
   * Global states
   */
  const [statIds, setStatIds] = useState<string[]>([]);

  /**
   * Edit profile API
   */
  const { mutateAsync: editRequestProfile, isLoading } = useEditAccess();

  /**
   * Get list apps by role enabled
   */
  const {
    isInitialLoading: isLoadingAppEnabled,
    data: appsByRoleEnabled,
    refetch: refetchAppsEnabled,
  } = useGetAppsByRoleEnabled(
    me?.globalRole?.globalRoleName || '',
    me?.role?.roleName || ''
  );

  /**
   * Get list apps by role disabled
   */
  const {
    isInitialLoading: isLoadingAppDisabled,
    data: appsByRoleDisabled,
    refetch: refetchAppsDisabled,
  } = useGetAppsByRoleDisabled(
    me?.globalRole?.globalRoleName || '',
    me?.role?.roleName || ''
  );

  /**
   * Advertisement api calls
   */
  const { data: advertisement } = useGetRandomAdvertisement();

  /**
   * Statistic api calls
   */
  const { data: statsList, isInitialLoading: isStatsLoading } =
    useGetStatisticSorted(idApp);
  const result = useGetStatisticQueries(statIds);

  /**
   * Init list of stats to fetch their data
   */
  useEffect(() => {
    if (statsList) {
      setStatIds(statsList.data.map((item: any) => item.id));
    }
  }, [statsList]);

  useEffect(() => {
    refetchAppsEnabled();
    refetchAppsDisabled();
  }, [refetchAppsDisabled, refetchAppsEnabled]);

  return (
    <>
      <HeaderPricing titlePage={t('dashboard:header.title')} />
      {forbiddenApp && (
        <>
          <DeniedAccessAppWarning />
          <SeparatorComponent />
        </>
      )}

      <BackdropLoader
        open={isLoadingAppEnabled || isLoadingAppDisabled || isStatsLoading}
      />
      {statsList ? (
        <>
          <DashboardStats
            stats={statsList.data}
            statQueries={result}
            advertisement={
              advertisement
                ? {
                    ...advertisement,
                    pubBaseUrl: config.pubSitesApiBaseUrl,
                  }
                : null
            }
          />
          <SeparatorComponent />
          <>
            <DashboardListing
              appsEnabled={appsByRoleEnabled?.data}
              appsDisabled={appsByRoleDisabled?.data}
            />
            <SeparatorComponent />
            <DashboardProfile
              editRequestProfile={editRequestProfile}
              isLoading={isLoading}
            />
          </>
        </>
      ) : (
        <>
          <DashboardListing
            appsEnabled={appsByRoleEnabled?.data}
            appsDisabled={appsByRoleDisabled?.data}
          />
          <SeparatorComponent />
          <DashboardProfile
            editRequestProfile={editRequestProfile}
            isLoading={isLoading}
          />
        </>
      )}
    </>
  );
};

export default Dashboard;
