import { useAppMutation } from '@exertis/template';
import { httpApi } from '@exertis/template';
import { compile } from 'path-to-regexp';

/**
 * Api delete user
 * @param id
 * @returns
 */
const deleteUser = async (id: number): Promise<void> => {
  return await httpApi.delete(
    compile('/accesses/:id', { encode: encodeURIComponent })({ id })
  );
};

/**
 * Hook to call api to delete user
 * @returns
 */
export default function useDeleteUser() {
  return useAppMutation(['accessLists'], deleteUser);
}
